@import "import-scss/variable";
//@import "node_modules/gijgo/css/gijgo.min.css";
//@import "node_modules/jquery.rateit/scripts/rateit.css";
@import "~bootstrap";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
@import "~bootstrap-select/dist/css/bootstrap-select.css";
@import "~hamburgers/dist/hamburgers.css";
@import "~owl.carousel/dist/assets/owl.carousel.css";
@import "~owl.carousel/dist/assets/owl.theme.default.css";
@import "~pguso-jquery-plugin-circliful/css/jquery.circliful.css";
@import "~pguso-jquery-plugin-circliful/css/material-design-iconic-font.min.css";
@import "~malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
@import "~bootstrap-star-rating/css/star-rating.min.css";
//@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";
@import "~fancybox/dist/css/jquery.fancybox.css";
@import "~fancybox/dist/helpers/css/jquery.fancybox-thumbs.css";
@import "import-scss/main";

body {
    line-height: 1;
    color: black;
    background: white;
}

ol, ul {
    list-style: none;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
    border-collapse: separate;
    border-spacing: 0;
}

caption, th, td {
    text-align: left;
    font-weight: normal;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: "";
}

blockquote, q {
    quotes: "" "";
}

body {
    font-family: $Lato-Regular;
}

.header-top {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
    @media screen and (max-width: 991px) {
        position: relative;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
}

.header-bottom {
    background-color: $gray-900;
    padding: 10px 0;

    @media screen and (max-width: 991px) {
        position: fixed;
        top: 64px;
        height: calc(100vh - 64px);
        left: 0;
        z-index: 2;
        padding: 50px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        transform: translateX(-150%);
        &.active {
            transform: translateX(0);
        }
        .main-phone-h {
            order: 2;
            justify-content: center;
            margin: auto;
            text-align: center;

            p {
                margin-right: 0;
                color: $white;
            }

            .phone-h {
                color: $white;
            }
        }
        .main-menu-h {
            order: 1;

            .header-bottom-left {
                flex-wrap: wrap;
                max-width: none;

                li {
                    width: 100%;
                    text-align: center;
                    margin-right: 0;
                    margin-bottom: 1.5rem;
                    padding: 0;

                }

            }
        }
        .header-bottom-right {
            order: 0;
            margin: 0 auto 1.5rem auto;
        }
    }
    @media screen and (max-width: 575px) {
        top: 54px;
        height: calc(100vh - 54px);
    }
}

.main-menu-h {
    li {
        &.mobile-hidden {
            display: none;
            @media screen and (max-width: 991px) {
                display: block;
            }
        }
    }
}

.logo-h {
    display: inline-block;
    width: 143px;
    height: 23px;
}

.shop-link-h {
    color: $red;
    font-size: 1.125rem;
    font-family: $Lato-Semibold;
    padding: 11px 15px;
    border-radius: 8px;
    border: 1px solid transparent;
    transition: 0.3s;
    width: 100%;
    max-width: 230px;

    &:hover {
        border-color: rgba(228, 0, 15, 0.2);
        color: $red;
        text-decoration: none;
    }

    @media screen and (max-width: 480px) {
        padding: 0;
        &:hover {
            border-color: transparent;
        }
    }
}

.main-phone-h {
    p {
        font-size: 1rem;
        margin-bottom: 0;
        margin-right: 2rem;
        color: $gray-600;
    }

    @media screen and (max-width: 767px) {
        margin-right: 0;
    }
}

.phone-h {
    font-size: 1.125rem;
    font-family: $Lato-Bold;
    color: $black;

    &:hover {
        color: $black;
        text-decoration: none;
    }
}

.main-search-h {
    padding: 5px 22px;
    position: relative;
    width: 64px;
    height: 40px;

    .search-ic {
        position: absolute;
        z-index: 1;
        right: 22px;
        top: 7px;
        //transform: translateY(-50%);
    }

    input {
        border-radius: 10px;
        border: 1px solid $gray-500;
        width: 100%;
        padding: 7.5px 19px 7.5px 19px;
        position: absolute;
        top: 0;
        right: 0;
        outline: none;
        font-size: 1rem;
        opacity: 0;
        transition: 0.3s;

        &:focus-within {
            width: 290px;
            opacity: 1;
            padding: 7.5px 64px 7.5px 15px;
        }
    }

    &:focus-within {
        input {
            width: 290px;
            opacity: 1;
            padding: 7.5px 64px 7.5px 15px;
        }
    }

    @media screen and (max-width: 991px) {
        width: 40px;
        padding: 5px;
        .search-ic {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        }
        input {
            opacity: 1;
            padding: 9.5px 19px 9.5px 19px;

            &:focus-within {
                padding: 9.5px 40px 9.5px 15px;
                width: 250px;
            }
        }
        &:focus-within {
            input {
                width: 250px;
                padding: 9.5px 40px 9.5px 15px;

            }
        }
    }
    @media screen and (max-width: 575px) {
        width: 30px;
        height: 30px;
        .search-ic {
            top: 5.5px;
            width: 15px;
            height: 19px;
        }
        input {
            padding: 4.5px 14px 4.5px 14px;
            border-radius: 6px;

            &:focus-within {
                padding: 4.5px 45px 4.5px 14px;
            }
        }
        &:focus-within {
            input {
                width: 290px;
                opacity: 1;
                padding: 4.5px 45px 4.5px 14px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        input {
            &:focus-within {
                width: calc(100vw - 70px);
            }
        }
        &:focus-within {
            input {
                width: calc(100vw - 70px);
            }
        }
    }
}

.header-top-left {
    max-width: 490px;
    width: 100%;
    @media screen and (max-width: 1199px) {
        max-width: none;
    }
    @media screen and (max-width: 991px) {
        width: calc(100% - 95px);
        .shop-link-h {
            display: none !important;
        }
    }
    @media screen and (max-width: 575px) {
        width: calc(100% - 70px);
    }
    @media screen and (max-width: 480px) {

        flex-wrap: wrap;

    }
}

.header-top-right {
    max-width: 690px;
    width: 100%;
    @media screen and (max-width: 1199px) {
        max-width: none;
    }
    @media screen and (max-width: 991px) {
        width: auto;
    }
}

.interpreter-search {
    max-width: 230px;
    width: 100%;

    .wrap-translate {
        width: 145px;

        #ytWidget {
            transform: scale(0.75);
            margin-right: 140px;
            //#yt-widget{
            //  transform: scale(0.75);
            //}
        }

    }

    @media screen and (max-width: 991px) {
        max-width: none;
        width: auto;
        .wrap-translate {
            display: none;
        }
        .main-search-h {
            order: 1;
        }
        img {
            display: none;
        }
    }
}

.header-bottom-left {
    padding-left: 0;
    list-style: none;
    max-width: 524px;
    width: 100%;
    padding-right: 0;
    margin-bottom: 0;

    li {
        margin-right: 16px;
        padding: 8.5px 0;

        .menu-link {
            position: relative;

            &:after {
                content: '';
                bottom: -5px;
                height: 1px;
                left: 50%;
                position: absolute;
                width: 0;
                transition: 0.3s;
                background-color: $red-dark;
                transform: translateX(-50%);
            }

            &:focus-within {
                &:after {
                    width: 100%;
                    left: 0;
                    transform: translateX(0);
                }
            }

            &.active {
                &:after {
                    width: 100%;
                    left: 0;
                    transform: translateX(0);
                }
            }
        }
    }
}

.menu-link {
    transition: 0.3s;
    font-size: 1.125rem;
    color: $gray-400;
    line-height: 1.3;
    display: inline-block;

    &:hover {
        color: $gray-600;
    }
}

.logo-personal-h {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    object-fit: cover;
}

.top-f {
    background-color: $gray-500;
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.city-f {
    padding-top: 30px;
    padding-bottom: 30px;
    @media screen and (max-width: 767px) {
        padding-top: 15px;
        padding-bottom: 15px;
        &:after {
            display: none;
        }
    }

    h4 {
        color: $gray-900;
    }

    p {
        font-size: 1.125rem;
        color: $gray-600;
    }

    .city-f-bottom {
        a {
            font-size: 1rem;
            color: $gray-900;
            line-height: 1.3;
            margin-bottom: 0.5rem;
            display: inline-block;
            vertical-align: middle;
        }

        p {
            font-size: 1rem;
            color: $gray-900;
            line-height: 1.3;
            margin-bottom: 0.5rem;
        }
    }

}

.city-f {
    position: relative;

    &:after {
        content: '';
        right: 0;
        height: 143px;
        width: 1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: $gray-600;
    }
}

.wrap-top-f {
    .city-f {
        min-width: 20%;

        &:last-child {
            &:after {
                display: none;
            }
        }

        @media screen and (max-width: 991px) {
            min-width: auto;
            &:nth-child(2n) {
                &:after {
                    display: none;
                }
            }
        }
    }
}

.top-f {
    position: relative;

    .link-up {
        position: absolute;
        right: 18px;
        bottom: 18px;
    }
}

.bottom-f {
    background-color: $gray-900;
    padding: 60px 0;
    @media screen and (max-width: 767px) {
        padding: 30px 0;
    }
}

.bottom-f-left {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    list-style: none;

    li {
        display: block;
        margin-bottom: 0.75rem;

        &:last-child {
            margin-bottom: 0;
        }

        .menu-link {
            font-size: 1rem;
        }
    }

    @media screen and (max-width: 991px) {
        .mobile-hidden {
            display: none;
        }
        li {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0.5rem;
            }
        }
    }
    @media screen and (max-width: 575px) {
        li {
            .menu-link {
                font-size: 0.875rem;
            }
        }
    }
}

.wrap-bottom-f-left {
    max-width: 525px;
    width: 100%;

    .bottom-f-left {
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }

    @media screen and (max-width: 991px) {
        padding-top: 39px;
        .mobile-hidden {
            display: none;
        }
    }
    @media screen and (max-width: 767px) {
        max-width: 220px;
    }
    @media screen and (max-width: 480px) {
        padding-top: 36px;
    }
}

.wrap-bottom-f-right {
    max-width: 520px;
    width: 100%;
    @media screen and (max-width: 1199px) {
        max-width: 172px;
    }
}

.social-network-f {
    .social-link-f {
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }

        @media screen and (max-width: 480px) {
            width: 20px;
            height: 20px;
            margin-right: 17px;
            &:after {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.social-link-f {
    position: relative;
    width: 28px;
    height: 23px;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        transition: 0.3s;
    }

    &:hover {
        &:after {
            opacity: 0.7;
        }
    }
}

.main-link-f {
    a {
        font-size: 1rem;
        color: $gray-600;
        line-height: 1.3;
        display: table;
        text-align: right;
        margin-left: auto;
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.main-banner {
    position: relative;
    padding-top: 46.7%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: 0.5;
    }

    @media screen and (max-width: 767px) {
        padding-top: 40px;
        padding-bottom: 40px;
        text-align: center;
        .main-banner-text {
            position: relative;
            top: 0;
            left: 0;
            transform: translateY(0) translateX(0);

        }
    }
}

.main-banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;

    h1 {
        color: $white;
        max-width: 610px;
        margin-bottom: 30px;
    }

    h3 {
        color: $white;
        max-width: 450px;
        margin-bottom: 40px;
    }
}

.question-text {
    max-width: 560px;
    width: 100%;

    h3 {
        font-family: $Lato-Bold;
        margin-bottom: 30px;
    }

    p {
        color: $gray-700;
        line-height: 1.3;
        font-size: 1.125rem;
    }
}

.question-video-text {
    padding-top: 70px;
    padding-bottom: 70px;
}

.question-video {
    max-width: 630px;
    width: 100%;
    padding-top: 63%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .fancybox.main-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.bg-video {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    //&:after {
    //  content: '';
    //  position: absolute;
    //  top: 50%;
    //  left: 50%;
    //  transform: translateX(-50%) translateY(-50%);
    //  width: 100px;
    //  height: 100px;
    //  background-image: url(../images/play-video.svg);
    //  background-position: center center;
    //  -webkit-background-size: contain;
    //  background-size: contain;
    //  background-repeat: no-repeat;
    //}
}

.main-owl-study {
    padding: 70px 0;
    background-color: $gray-500;
}

.owl-study {
    border-radius: 8px;
    background-color: $white;
    position: relative;

    .item {
        padding: 65px 95px;
        @media screen and (max-width: 1199px) {
            padding: 40px 30px;
        }
        @media screen and (max-width: 991px) {
            padding: 40px 15px;
        }
    }

    .owl-nav {
        button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 0;
            outline: none;
            width: 13px;
            height: 24px;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            -webkit-background-size: contain !important;
            background-size: contain !important;

            span {
                font-size: 0;
            }

            &.owl-prev {
                left: 19px;
                background-image: url(../images/owl-prev.svg);
            }

            &.owl-next {
                right: 19px;
                background-image: url(../images/owl-next.svg);
            }
        }
    }

    @media screen and (max-width: 1199px) {
        .owl-nav {
            button {
                &.owl-prev {
                    left: 5px;
                }

                &.owl-next {
                    right: 5px;
                }
            }
        }
    }
}

.owl-study-left {
    max-width: 500px;
    padding-right: 10px;
    width: 100%;

    h4 {
        font-family: $Lato-Semibold;
        color: $gray-600;
        margin-bottom: 3.875rem;
    }

    .wrap-study-logo {
        margin-bottom: 27px;
    }

    p {
        font-size: 1.125rem;
        color: $gray-700;
        line-height: 1.3;
    }

    @media screen and (max-width: 991px) {
        text-align: center;
        max-width: 400px;
        padding-right: 0;
        margin: auto;
        h4 {
            margin-bottom: 1.5rem;
        }
    }
}

.wrap-study-logo {
    .study-owl-logo {
        margin-right: 2rem;
    }

    h3 {
        font-family: $Lato-Bold;
    }
}

.study-owl-logo {
    width: 58px;
    max-width: 58px;
    height: 63px;
    object-fit: contain;
}

.owl-study-right {
    max-width: 530px;
    padding-top: 30.2%;
    position: relative;
    border-radius: 8px;
    width: 100%;
    align-self: flex-start;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 8px;
        object-fit: cover;
    }

    &:after {
        content: '';
        position: absolute;
        top: -30px;
        left: 30px;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: $gray-500;
    }

    @media screen and (max-width: 1199px) {
        &:after {
            top: -10px;
            left: 10px;
        }
    }
    @media screen and (max-width: 991px) {
        margin: auto;
        max-width: 400px;
        padding-top: 40%;

    }
}

.main-news {
    padding-top: 50px;
    padding-bottom: 100px;

    h3 {
        width: 100%;
        margin-bottom: 30px;
        font-family: $Lato-Bold;
    }

    @media screen and (max-width: 575px) {
        text-align: center;

    }
}

.box-news-img {
    width: 225px;
    height: 214px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.news-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    opacity: 0;

    .news-hover-text {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
        color: $white;
        font-family: $Lato-Semibold;
        font-size: 1.125rem;
    }
}

.box-news-text {
    .news-data {
        color: $gray-700;
        margin-bottom: 1.125rem;
    }

    h4 {
        margin-bottom: 1.75rem;
    }

    p {
        color: $gray-700;
        margin-bottom: 0;
        line-height: 1.3;
    }

    @media screen and (max-width: 575px) {
        display: flex;
        flex-wrap: wrap;

        .news-data {
            width: 100%;
            order: 1;

        }
        p {
            width: 100%;
            order: 2;
        }
        h4 {
            width: 100%;
            order: 0;
            margin-bottom: 1.125rem;
        }
    }
}

.box-news {
    margin: 0 10px;
    width: calc(50% - 20px);
    position: relative;

    .box-news-img {
        margin-right: 40px;
        @media screen and (max-width: 991px) {
            margin-right: 20px;

        }
        @media screen and (max-width: 575px) {
            margin-right: 0;
            margin-bottom: 1.5rem;

        }
    }

    &:hover {
        .news-hover {
            opacity: 1;
        }
    }

    @media screen and (max-width: 1199px) {
        width: 100%;
    }
    @media screen and (max-width: 575px) {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        padding-bottom: 1.5rem;
        &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 5px;
            width: calc(100% - 10px);
            height: 1px;
            background-color: $gray-500;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

.wrap-box-news {
    padding: 0 5px;

    .box-news {
        margin-bottom: 1.25rem;
    }

    @media screen and (max-width: 575px) {
        .box-news {
            margin-bottom: 1.75rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.mb-20 {
    margin-bottom: 1.25rem;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
    background-color: $red;
}

.hamburger {
    padding: 0;

    @media screen and (max-width: 991px) {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }
    @media screen and (max-width: 575px) {

        .hamburger-box {
            width: 25px;
            height: 20px;
        }
        .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
            width: 25px;
            height: 2px;
        }
        .hamburger-inner:after {
            bottom: -6px;
        }
        .hamburger-inner:before {
            top: -6px;
        }
    }
}

.padding-page {
    padding-top: 50px;
    padding-bottom: 100px;
    min-height: calc(100vh - 302px);

    &.height-a {
        min-height: auto;
    }
}

.bread-crumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        position: relative;
        margin-left: 28px;

        &:after {
            content: '';
            width: 7px;
            height: 7px;
            border-radius: 100%;
            background-color: $gray-550;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -17px;
        }

        &:last-child {
            margin-right: 0;
        }

        &:first-child {
            margin-left: 0;

            &:after {
                display: none;
            }
        }

        a {
            display: inline-block;
            color: $gray-700;
            font-size: 0.875rem;
            transition: 0.3s;
            line-height: 1.2;

            &:hover {
                color: $red;
            }
        }
    }
}

.search-input {
    border: 1px solid $gray-500;
    padding: 11.5px 10px;
    border-radius: 10px;
    width: 100%;
    max-width: 304px;

    input {
        padding: 0 10px;
        border: none;
        outline: none;
        font-size: 1rem;
        width: calc(100% - 20px);
    }
}

.my-list-search {
    .search-input {
        margin-right: 40px;
    }

    @media screen and (max-width: 767px) {
        .search-input {
            margin-right: 15px;
        }
    }
}

.nav.nav-tabs.nav-tabs-list {
    width: 100%;
    max-width: 305px;
    margin-right: 20px;
    border: none;
    border-radius: 8px;
    background-color: $gray-500;
    overflow: hidden;

    .nav-link.active, .nav-item.show .nav-link {
        background-color: transparent;
    }

    .nav-item.nav-item-list {
        width: 100%;
        margin-bottom: 0;

        .nav-link.nav-link-list {
            border: none;
            border-radius: 0;
            color: $black;
            padding: 15.5px 10px 15.5px 35px;
            position: relative;
            transition: 0.3s;
            overflow: hidden;

            .wrap-bg-circle {
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
                opacity: 0;
                transition: 0.3s;

                .bg-circle {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    width: 8px;
                    border-radius: 100%;
                    height: 8px;
                    transition: 0.3s;
                }

            }

            &.active {
                color: $white;

                .wrap-bg-circle {
                    opacity: 1;

                    .bg-circle {
                        height: 700px;
                        width: 700px;
                    }
                }
            }

            &:hover {
                .wrap-bg-circle {
                    opacity: 1;
                }

            }
        }
    }

    @media screen and (max-width: 991px) {
        max-width: 260px;
        margin-right: 10px;
        .nav-item.nav-item-list {
            .nav-link.nav-link-list {
                padding: 15.5px 10px 15.5px 20px;

                .wrap-bg-circle {
                    left: 10px;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        max-width: none;
        margin-right: 0;
        .nav-item.nav-item-list {
            .nav-link.nav-link-list.active {
                .wrap-bg-circle {
                    .bg-circle {
                        width: 1000px;
                        height: 1000px;
                    }
                }
            }
        }
    }
}

.link-list-text {
    position: relative;
    z-index: 1;
}

.main-box-list {
    border-radius: 8px;
    border: 1px solid $gray-200;
    padding: 65px 20px 25px 20px;
    position: relative;
    transition: 0.3s;
    //align-items: center;

    .main-run-level {
        margin-bottom: 1.875rem;
    }

    .list-img {
        //position: absolute;
        //top: 50%;
        //right: 20px;
        //transform: translateY(-50%);
        margin-top: -26px;
        margin-bottom: 16px;
    }

    &:hover {
        -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
    }

    &.next-webinar {
        background-color: $gray-400;
        border: none;

        .next-webinar-text {
            position: absolute;
            top: 30px;
            left: 25px;
            @media screen and (max-width: 991px) {
                top: 15px;
                left: 20px;
            }
        }

        .data-event {
            background-color: $white;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        }
    }

    &.cursesList {
        position: relative;

        .main-box-list-text{
            position: relative;
            padding-bottom: 98px;
            align-self: stretch;
        }
        .main-box-circle {
            position: absolute;
            top: 0;
            right: 0;
            width: 56px;
            height: 56px;
            opacity: 0;
            visibility: hidden;
            display: none;
            transition: 0.3s;
            font-size: 0;

            div {
                canvas {
                    width: 56px !important;
                    height: 56px !important;
                }
            }

            &:after {
                top: 5px;
                left: 5px;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
            }

            .main-circle-text {
                letter-spacing: 0.02em;
                font-size: 16px;
                line-height: 19px;
                font-family: 'Lato-Regular';
                padding-bottom: 2px;
            }

            @media screen and (max-width: 991px) {
                display: block;
            }
            @media screen and (max-width: 575px) {
                div {
                    canvas {
                        width: 56px !important;
                        height: 56px !important;
                    }
                }
            }
        }

        .link-arrow-red {
            margin-top: calc(1.875rem + 44px);
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .main-run-level {
            position: absolute;
            left: 20px;
            bottom: calc(1.875rem + 48px);
            margin-bottom: 0;
            transition: 0.3s;
            @media screen and (max-width: 991px) {
                left: 10px;
                bottom: calc(1.875rem + 43px);
            }
        }
    }


    @media screen and (max-width: 991px) {
        padding: 45px 10px 20px 10px;
        .name-curses {
            top: 15px;
        }
        .list-img {
            order: 0;
            margin: 0 auto 1.5rem auto;
            width: 100%;
            max-width: 282px;
            position: static;
            transform: translateY(0);
        }
        .main-box-list-text {
            order: 1;
            width: 100%;
        }
    }
    @media screen and (max-width: 767px) {
        border: none;
        border-bottom: 1px solid $gray-200;
        border-radius: 8px 8px 0 0;
        &:hover {
            box-shadow: none;
        }
    }
}

.next-webinar-text {
    color: $gray-600;
    line-height: 1.3;
    font-size: 0.875rem;
}


.main-courses {
    &.view-list-line {
        padding-left: 40px !important;

        .main-run-level {
            //display: none !important;
            top: 50%;
            transform: translateY(-50%);
            right: 22px;
            left: auto;
            bottom: auto;
            z-index: 1;
            padding: 13px 15px 13px 20px;
            opacity: 0;
            @media screen and (max-width: 1199px) {
                opacity: 1;
            }
            @media screen and (max-width: 991px) {
                display: none !important;
            }
        }

        .main-box-circle {
            visibility: visible;
            opacity: 1;
        }

        .main-block-list {
            padding: 24px 0;
            border-bottom: 1px solid $gray-200;
            margin-bottom: 0;
            position: relative;

            .main-box-list {
                border-radius: 0;
                border: none;
                padding: 0;
                position: static;
                display: inline-block !important;
                width: auto;
                max-width: calc(100% - 320px);

                .new-curses {
                    display: none;
                }

                .list-img {
                    display: none;
                }

                .name-curses {
                    display: none;
                }

                .main-box-list-text {
                    margin-right: 0 !important;
                    max-width: none;
                    width: auto;
                    position: relative;
                    padding-bottom: 0;
                    p {
                        display: none;
                    }


                }

                h4 {
                    margin-bottom: 0 !important;
                    transition: 0.3s;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    letter-spacing: 0.02em;
                }

                .link-arrow-red {
                    opacity: 0 !important;
                    position: absolute;
                    top: 0;
                    margin-top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }

                &:hover {
                    box-shadow: none;

                    h4 {
                        color: $red;
                    }

                    .main-run-level {
                        opacity: 1;
                    }
                }

                @media screen and (max-width: 991px) {
                    max-width: calc(100% - 72px);
                    h4 {
                        white-space: normal;
                    }
                }
            }
        }

        @media screen and (max-width: 1199px) {
            padding-left: 0 !important;
        }
        @media screen and (max-width: 991px) {

            .main-block-list {
                padding-top: 8px;
                padding-bottom: 48px;
                border: none;
            }
        }
    }
}

.main-box-list-text {
    max-width: 500px;
    width: calc(100% - 302px);

    h4 {
        color: $gray-900;
    }

    p {
        color: $gray-700;
        margin-bottom: 1.5rem;
        line-height: 1.3;
    }
}

.list-img {
    width: 282px;
    height: 243px;
    object-fit: cover;
    border-radius: 8px;
}

.main-run-level {
    padding: 15px 15px 15px 20px;
    border-radius: 4px;
    background-color: $gray-500;
    width: 100%;
    max-width: 280px;
}

.wrap-run-level {
    height: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    background-color: $white;
    width: calc(100% - 40px);
}

.run-level-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: $gray-700;
}

.main-wrap-list {
    .main-block-list {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 40px;

        }

        &.view-list-line {
            margin-bottom: 0;

            &:last-child {
                margin-bottom: 40px;
                border-bottom: none;
            }
        }
    }

    &.main-webinar-list {
        .main-block-list {
            .main-box-list {
                padding: 60px 30px 30px 25px;

                .main-box-list-text {
                    p {
                        margin-bottom: 1.875rem;
                    }
                }

                .list-img {
                    width: 274px;
                    height: 261px;
                    margin-top: -27px;
                    margin-bottom: 3px;
                }

                @media screen and (max-width: 991px) {
                    padding: 45px 10px 20px 10px;
                    .list-img {
                        margin: 1rem auto 1.5rem auto;
                        width: 268px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .main-block-list {
            &:last-child {
                .main-box-list {
                    border-bottom: none;
                }
            }
        }
    }
}

.name-curses {
    position: absolute;
    top: 25px;
    left: 20px;
    padding-left: 20px;
    color: $gray-600;
    font-size: 0.875rem;

}

.color-curses {
    top: 2px;
    position: absolute;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.new-curses {
    position: absolute;
    top: 0;
    left: 0;
    font-family: $Lato-Bold;
    text-align: center;
    padding: 6px 25px;
    color: $white;
    border-radius: 8px 0;
    text-transform: uppercase;
}

.ul-number-links {
    padding: 0;
    margin-bottom: 0;
    list-style: none;

    .li-number-links {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.news-list-filter {
    max-width: 25rem;

    .main-wrap-datepicker {
        height: 0;
        overflow: hidden;
        opacity: 0;
        margin-bottom: 0;
    }

    .red-arrow-tick {
        margin-bottom: 20px;
        position: relative;
    }

    .news-filter-tab {
        .filter-tab {
            margin-bottom: 20px;
        }
    }
}

.number-link {
    background: none;
    padding: 4px 5px;
    border-radius: 3px;
    border: 1px solid $gray-500;
    line-height: 1.3;
    color: $gray-700;
    text-align: center;
    min-width: 30px;
    display: inline-block;
    transition: 0.3s;

    &:focus {
        outline: 0;
    }

    &:hover {
        background-color: $gray-500;
        color: $gray-700;
    }

    &.active {
        background-color: $red;
        border-color: $red;
        color: $white;

        &:hover {
            background-color: $red;
            border-color: $red;
            color: $white;
        }
    }
}

.view-tab-line {
    width: 23px;
    height: 22px;
    position: relative;
    cursor: pointer;

    .view-line {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 3px;
        transform: translateY(-50%);
        background-color: $gray-700;
        transition: 0.3s;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $gray-700;
        transition: 0.3s;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $gray-700;
        transition: 0.3s;
    }

    &:hover {
        .view-line {
            background-color: $gray-900;
        }

        &:after {
            background-color: $gray-900;
        }

        &:before {
            background-color: $gray-900;
        }
    }

    &.active {
        .view-line {
            background-color: $red;
        }

        &:after {
            background-color: $red;
        }

        &:before {
            background-color: $red;
        }

        &:hover {
            .view-line {
                background-color: $red;
            }

            &:after {
                background-color: $red;
            }

            &:before {
                background-color: $red;
            }
        }
    }
}

.main-view-tab {
    .view-tab-line {
        margin-right: 20px;
    }
}

.view-tab-box {
    position: relative;
    width: 23px;
    height: 23px;
    cursor: pointer;

    .tab-box {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: $gray-700;
        transition: 0.3s;

        &:nth-child(1) {
            top: 0;
            left: 0;
        }

        &:nth-child(2) {
            top: 0;
            right: 0;
        }

        &:nth-child(3) {
            bottom: 0;
            left: 0;
        }

        &:nth-child(4) {
            bottom: 0;
            right: 0;
        }
    }

    &:hover {
        .tab-box {
            background-color: $gray-900;
        }
    }

    &.active {
        .tab-box {
            background-color: $red;
        }

        &:hover {
            .tab-box {
                background-color: $red;
            }
        }
    }
}

.search-hint {
    padding: 20px 0 20px 30px;

    .search-hint-top {
        margin-bottom: 40px;

        h3 {
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 20px 0;
    }
}

.lessons-list-top {
    margin-bottom: 50px;

    h2 {
        margin-bottom: 0;
    }
}

.main-circle-rate {
    padding: 30px 0;
    background-color: $gray-500;
}

.main-box-circle {
    position: relative;
    width: 90px;
    height: 90px;
    margin: 0 auto;
    overflow: hidden;

    .main-circle {
        position: absolute;
        top: 0;
        left: 0;
    }

    &:after {
        content: '';
        position: absolute;
        top: 9px;
        left: 9px;
        width: calc(100% - 18px);
        height: calc(100% - 18px);
        background-color: $gray-500;
        border-radius: 100%;
    }

    .main-circle-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 1.25rem;
        color: $gray-900;
        font-family: $Lato-Bold;
        z-index: 1;
    }

    @media screen and (max-width: 575px) {
        width: 70px;
        height: 70px;
        div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            canvas {
                width: 70px !important;
                height: 155px !important;
            }
        }
        .main-circle-text {
            width: auto;
            height: auto;
            font-size: 1rem;
        }
    }
}

.circle-rate-text {
    background-color: $white;
    border-radius: 10px;
    padding: 25px 30px;
    max-width: 760px;
    width: 100%;
    align-self: flex-start;

    h4 {
        color: $gray-900;
    }

    p {
        color: $gray-900;
        font-size: 1.125rem;
    }

    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;

        li {
            padding-left: 20px;
            position: relative;
            line-height: 1.3;
            color: $gray-700;
            margin-bottom: 1rem;

            &:after {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                left: 0;
                top: 5px;
                background-color: $gray-600;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 30px 15px;
    }
}

.main-circle-right {
    padding: 0 15px;
    max-width: 460px;
    width: 100%;

    h6 {
        color: $gray-900;
        margin-bottom: 1rem;
        font-size: 0.875rem;
        text-align: center;
    }

    @media screen and (max-width: 767px) {
        margin-top: 14px;
        padding: 0;
        padding-bottom: 0 !important;
        h6 {
            font-size: 0.75rem;
        }
    }
}

.main-block-circle {
    @media screen and (max-width: 575px) {
        margin-top: 14px;
        padding: 0;
    }
}

.circle-lesson {
    width: 90px;
    height: 90px;
    background-color: $gray-400;
    border-radius: 100%;
    margin: 0 auto;
    position: relative;

    .circle-lesson-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        font-size: 2.25rem;
        color: $red;
        font-family: $Lato-Bold;
    }

    @media screen and (max-width: 575px) {
        width: 70px;
        height: 70px;
        .circle-lesson-text {
            font-size: 1.25rem;
        }
    }
}

.wrap-lesson-list {
    padding: 60px 0;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 15px;
        width: calc(100% - 30px);
        bottom: 0;
        height: 1px;
        background-color: $gray-500;
    }

    &:last-child {
        &:after {
            display: none;
        }
    }

    p {
        margin-bottom: 1.5rem;
        line-height: 1.3;
        color: $gray-900;
    }

    .link-arrow-red {
        margin-top: 1.5rem;
    }

    @media screen and (max-width: 991px) {
        padding: 40px 0 30px 0;
    }
}

.lesson-list-right {
    position: relative;
    padding-top: 27%;

    img {
        border-radius: 8px;
        position: absolute;
        right: 15px;
        top: 0;
        height: 100%;
        max-width: 630px;
        z-index: 1;
        width: calc(100% - 30px);
        object-fit: cover;
    }

    &:after {
        content: '';
        top: -30px;
        right: -15px;
        border-radius: 8px;
        position: absolute;
        height: 100%;
        width: calc(100% - 30px);
        max-width: 630px;
        background-color: $gray-500;
    }

    .bg-video {
        border-radius: 8px;
        right: 15px;
        top: 0;
        height: 100%;
        left: auto;
        max-width: 630px;
        z-index: 2;
        width: calc(100% - 30px);

        &:after {
            width: 114px;
            height: 114px;
        }
    }

    .fancybox.main-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: 1370px) {
        &:after {
            top: -15px;
            right: -0px;
        }
    }
    @media screen and (max-width: 767px) {
        padding-top: 45%;
        &:after {
            top: -10px;
            right: 5px;
        }
    }

}

.test-page {
    position: relative;
}

.test-page-top {
    position: relative;

    p {
        color: $gray-700;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 143px;
        height: 1px;
        background-color: $gray-500;
    }
}

.wrap-checkbox {
    .checkbox__text {
        font-family: 'Lato-Semibold';
    }
}

.main-test-line {
    height: 10px;
    position: relative;
    max-width: 250px;
    width: 100%;
    border-radius: 5px;
    background-color: $gray-500;
    overflow: hidden;

    .test-line {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: $red;
    }
}

.main-test-page {
    max-width: 735px;
    width: calc(100% - 460px);
    padding-right: 15px;

    h3 {
        max-width: 610px;
        font-family: $Lato-Bold;
        line-height: 1.3;
    }

    &.test-img-block {
        max-width: none;
        padding-right: 0;
        width: 100%;

        .main-checkbox {
            padding: 0 5px;

            .wrap-checkbox {
                width: 20%;
                @media screen and (max-width: 991px) {
                    width: 33.333%;
                }
                @media screen and (max-width: 767px) {
                    width: 50%;
                }
                @media screen and (max-width: 480px) {
                    width: 100%;
                }
            }
        }

        .wrap-checkbox {
            padding: 0 10px;

            .checkbox {
                width: 100%;
                position: relative;
                padding-top: 100%;

                .checkbox__text {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    overflow: hidden;

                    &:before {
                        z-index: 1;
                        background-color: $white;
                        left: auto;
                        top: 10px;
                        right: 10px;
                    }

                    &:after {
                        top: 19.5px;
                        left: auto;
                        right: 16.5px;
                        z-index: 1;
                    }
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }
        }
    }

    @media screen and (max-width: 991px) {
        width: 100%;
        max-width: none;
        padding-top: 0;
        h3 {
            max-width: none;
        }
    }
}

.test-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 460px;
    border-radius: 8px;
    max-height: 275px;
    object-fit: cover;
    @media screen and (max-width: 991px) {
        position: static;
        margin: 0 auto;
        display: block;
    }
}

.test-page-bottom {
    p {
        color: $gray-600;
    }
}

.finish-page {
    padding-top: 100px;

    h2 {
        margin-bottom: 60px;
    }

    @media screen and (max-width: 991px) {
        padding: 50px;
        h2 {
            margin-bottom: 30px;
        }
    }
}

.main-lessons-text-info {
    padding: 30px 0;
    background-color: $gray-500;
}

.lessons-text-info {
    padding: 0 15px;
    background-color: $white;
    border-radius: 8px;
}

.lessons-purpose {
    max-width: 595px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
        color: $gray-900;
        line-height: 1.3;
        margin-bottom: 0;
    }

    @media screen and (max-width: 991px) {
        padding: 15px 0;
    }
}

.lessons-structure {
    max-width: 595px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;

    ul {
        padding: 0;
        margin-bottom: 0;
        list-style: none;

        li {
            display: inline-block;
            position: relative;
            padding-left: 20px;
            margin-bottom: 8px;

            p {
                margin-bottom: 0;
                color: $gray-900;
                padding-bottom: 5px;
                display: inline;
                line-height: 1.5;
                border-bottom: 1px dashed $gray-600;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 7px;
                width: 8px;
                height: 8px;
                background-color: $gray-550;
                border-radius: 100%;


            }
        }
    }

    @media screen and (max-width: 991px) {
        padding: 15px 0 20px 0;
    }
}

.main-lessons {
    padding: 60px 0 30px 0;
    @media screen and (max-width: 991px) {
        padding: 30px 0 0px 0;
    }
}

.open-presentation {
    border-radius: 8px;
    background-color: $gray-400;
    padding: 35px 60px 35px 30px;
    max-width: 630px;
    width: 100%;
    border: 1px solid $gray-500;

    h3 {
        color: $gray-900;
    }

    @media screen and (max-width: 991px) {
        max-width: none;
        padding: 15px 10px;
    }
    @media screen and (max-width: 575px) {
        .open-presentation-left {
            order: 1;

        }
        .pgf-ic {
            order: 0;
            margin-bottom: 1.5rem;
        }
    }
}

.main-ul {
    padding: 0;
    margin-bottom: 0;
    list-style: none;

    li {
        padding-left: 20px;
        position: relative;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 5px;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: $gray-550;
        }
    }
}

.content-presentation {
    max-width: 600px;
    width: 100%;
    @media screen and (max-width: 991px) {
        max-width: none;
    }
}

.main-lessons-middle {
    padding: 0 5px;

    .main-lessons-box {
        margin-bottom: 30px;

        * {
            display: block !important;
        }

        .main-lessons-video {
            display: block !important;
        }
    }

}

.main-lessons-box {
    width: 50%;
    padding: 0 10px;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.main-lessons-video {
    width: 100%;
    padding-top: 56.5%;
    position: relative;
    border-radius: 8px;

    .main-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        display: block !important;
    }

    .img-block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
    }

    .bg-video {
        border-radius: 8px;
    }
}

.wrap-main-lessons {
    .main-lessons {
        &:nth-child(even) {
            background-color: $gray-400;

            .open-presentation {
                background-color: $white;
            }
        }
    }
}

.materials-study {
    padding-top: 60px;
    padding-bottom: 60px;
    border-top: 1px solid $gray-500;
    @media screen and (max-width: 767px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.wrap-materials-input {
    display: table;
    position: relative;

    a {
        line-height: 1.3;
        color: $gray-700;
        margin-bottom: 0;
        display: inline-flex;
        align-items: center;
        span{
            display: block;
        }
    }

    input {
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.wrap-materials-study {
    padding-bottom: 60px;

    .wrap-materials-input {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: 767px) {
        padding-bottom: 30px;
    }
}

.main-video.fancybox-content {
    max-width: 1280px;
    padding-top: 0;
    height: 720px;
    width: 100%;
}

.fancybox-slide--html {
    padding: 15px;
}

.fancybox-slide--html {
    .fancybox-close-small {

    }
}

.news-list-page {
    .bread-crumb {
        margin-bottom: 0.75rem;
    }
}

.wrap-datepicker {
    width: 100%;
    max-width: 143px;

    input {
        width: 100%;
        font-size: 0.875rem;
        line-height: 1.3;
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid $gray-600;
        outline: none;
        font-family: $Lato-Regular;
        color: $red !important;
        height: auto;

        &:focus {
            border: 1px solid $gray-600;
            box-shadow: none;
        }

        &::-webkit-input-placeholder {
            color: $gray-600;
            font-family: $Lato-Regular;
            transition: 0.3s;
        }

        &::-moz-placeholder {
            color: $gray-600;
            font-family: $Lato-Regular;
            transition: 0.3s;
        }

        &:-moz-placeholder {
            color: $gray-600;
            font-family: $Lato-Regular;
            transition: 0.3s;
        }

        &:-ms-input-placeholder {
            color: $gray-600;
            font-family: $Lato-Regular;
            transition: 0.3s;
        }

        &:focus::-webkit-input-placeholder {
            color: transparent
        }

        &:focus::-moz-placeholder {
            color: transparent
        }

        &:focus:-moz-placeholder {
            color: transparent
        }

        &:focus:-ms-input-placeholder {
            color: transparent
        }
    }
}

.datepicker-dropdown.datepicker-orient-bottom {
    &:before {
        display: none;
    }

    &:after {
        display: none;
    }
}

.dropdown-menu {
    padding: 14px 20px 20px 20px;
    margin-top: 10px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 0.5px solid $gray-500;
}

.datepicker.datepicker-dropdown {
    .datepicker-days {
        .table-condensed {
            thead {
                tr {
                    .dow {
                        padding: 6px 5px;
                        text-align: center;
                        min-width: 32px;
                        width: 32px;
                        max-width: 32px;
                        color: $gray-600;
                        line-height: 1.3;
                        font-size: 0.875rem;
                    }

                    &:nth-child(2) {

                        .prev {
                            align-items: center;
                            font-size: 0;
                            position: relative;

                            &:hover {
                                background-color: transparent;
                            }

                            &:after {
                                content: '';
                                position: absolute;
                                top: 19.5px;
                                left: 5px;
                                width: 5px;
                                height: 9px;
                                background-image: url(../images/prev-data.svg);
                                -webkit-background-size: contain;
                                background-size: contain;
                                background-position: center center;
                                background-repeat: no-repeat;
                            }
                        }

                        .next {
                            align-items: center;
                            font-size: 0;
                            position: relative;

                            &:hover {
                                background-color: transparent;
                            }

                            &:after {
                                content: '';
                                position: absolute;
                                top: 19.5px;
                                right: 5px;
                                width: 5px;
                                height: 9px;
                                background-image: url(../images/next-data.svg);
                                -webkit-background-size: contain;
                                background-size: contain;
                                background-position: center center;
                                background-repeat: no-repeat;
                            }
                        }

                        .datepicker-switch {
                            align-items: center;
                            font-size: 0.875rem;
                            padding: 1rem 0;
                        }
                    }
                }
            }

            tbody {
                tr {
                    .day {
                        font-size: 0.875rem;
                        padding: 6px 5px;
                        text-align: center;
                        min-width: 32px;
                        width: 32px;
                        max-width: 32px;
                        position: relative;
                        color: $gray-900;

                        &:after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            width: 27px;
                            height: 23px;
                            background-color: transparent;
                            z-index: -1;
                            transition: 0.3s;
                        }

                        &:hover {
                            background-color: transparent;

                            &:after {
                                background-color: $gray-500;
                            }
                        }

                        &.active {
                            background-color: transparent;
                            border: none;
                            background-image: none;
                            text-shadow: none;

                            &:after {
                                background-color: rgba(228, 0, 15, 0.2);
                            }

                            &:hover {
                                &:after {
                                    background-color: rgba(228, 0, 15, 0.2);
                                }

                            }
                        }

                        &.old {
                            color: $gray-600;
                        }
                    }
                }
            }
        }
    }
}

.news-filter-tab {
    .filter-tab {
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }

        @media screen and (max-width: 767px) {
            margin-right: 1rem;
        }
    }

    &.search-filter {
        max-width: 470px;
        justify-content: space-between !important;

        .filter-tab {
            margin-right: 1rem;


            &:last-child {
                margin-right: 0;
            }
        }

        @media screen and (max-width: 767px) {
            justify-content: center !important;
        }
    }
}

.main-wrap-datepicker {
    transition: 0.3s;

    .wrap-datepicker {
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }

        @media screen and (max-width: 767px) {
            margin-right: 10px;
            max-width: 140px;
        }
    }
}

.certificates-page {
    padding: 40px;
    background-color: #0E3266;
}

.wrap-certificates-page {
    background-color: $white;
    padding-top: 50px;
    padding-bottom: 50px;

    h1 {
        margin-bottom: 2rem;
    }

    p {
        margin-bottom: 1.5rem;
    }
}

.filter-tab {
    display: inline-block;
    color: $gray-700;
    line-height: 1rem;
    position: relative;
    transition: 0.3s;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        bottom: -5px;
        width: 0;
        transition: 0.3s;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        background-color: transparent;
    }

    &:hover {
        color: $gray-900;
    }

    &.active {
        color: $red;

        &:after {
            width: 100%;
            background-color: rgba(228, 0, 15, 0.2);
        }

        &:hover {
            color: $red;
        }
    }
}

.wrap-datepicker-btn {
    @media screen and (max-width: 767px) {
        position: static;
    }


    .main-wrap-datepicker {
        position: absolute;
        right: 0;
        bottom: -52px;
        opacity: 0;
        @media screen and (max-width: 767px) {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        }

        .wrap-datepicker {
            width: 100vh;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.wrap-datepicker-btn.active + .main-wrap-datepicker {
    overflow: visible;
    height: 30px;
    opacity: 1;
    margin-bottom: 1rem;
}

.news-list-top-right {
    margin-top: 33px;
    @media screen and (max-width: 767px) {
        margin-top: 1rem;
    }
}

.news-list-top {
    .search-input {
        max-width: 304px;
    }
}

.datepicker-dropdown.datepicker-orient-top {
    &:before {
        display: none;
    }

    &:after {
        display: none;
    }
}

.news-filter-tab {
    @media screen and (max-width: 575px) {
        width: 100%;
    }
}

.news-top-text {
    padding: 0 5px;
}

.news-top-text {
    padding: 0 10px;

    p {
        color: $gray-900;
        margin-bottom: 0.75rem;
    }

    ul {
        padding: 0;
        margin: 0;

        li {
            color: $gray-700;
            padding-left: 20px;
            position: relative;
            line-height: 1.3;
            margin-bottom: 0.75rem;

            a {
                color: $red;
                border: none;
                border-bottom: 1px dashed $red;
                padding: 0;
                background-color: transparent;
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 6px;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: $gray-700;
            }
        }
    }
}

.news-top-text {
    max-width: 800px;
    width: 100%;
    padding: 0 10px;
}

.wrap-news-top-img {
    padding: 0 10px;
    max-width: 487px;
    width: 100%;
}

.news-top-text {
    padding: 0 10px;

    p {
        margin-bottom: 1.25rem;
        color: $gray-900;
        line-height: 1.3;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.news-page-middle {
    padding: 0 5px;
    @media screen and (max-width: 991px) {
        .news-top-text {
            order: 0;
            max-width: none;
        }
    }
}

.news-top-img {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    padding-top: 67%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.news-page-bottom-video {
    padding: 0 10px;
    max-width: 620px;
    width: 100%;
}

.news-page-bottom-text {
    padding: 0 10px;
    max-width: 650px;
    width: 100%;

    p {
        color: $gray-900;
        margin-bottom: 1.25rem;
        line-height: 1.3;
    }
}

.news-page-bottom {
    padding: 0 5px;
    @media screen and (max-width: 991px) {
        .news-page-bottom-video {
            max-width: none;
        }
        .news-page-bottom-text {
            max-width: none;
        }
    }
}

.news-page-top {
    padding: 0 5px;

    a {
        color: #E4000F;
    }

    @media screen and (max-width: 991px) {
        .news-top-text {
            order: 1;
            max-width: none;
        }
        .wrap-news-top-img {
            order: 0;
            max-width: none;
        }
    }
}

.owl-new-img {
    position: relative;
    padding-top: 67%;
    border-radius: 10px;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .main-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.owl-news {
    border-radius: 10px;
    overflow: hidden;

    .owl-nav {
        button {
            position: absolute;
            top: 0;
            height: 100%;
            width: 34px;
            background-color: rgba(255, 255, 255, 0.7) !important;
            outline: none;
            transition: 0.3s;

            &:hover {
                background-color: rgba(255, 255, 255, 0.9) !important;
            }

            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 13px;
                height: 24px;
                background-position: center center;
                -webkit-background-size: contain;
                background-size: contain;
                background-repeat: no-repeat;
                font-size: 0;
                z-index: 1;
            }

            &.owl-prev {
                left: 0;
                border-radius: 10px 0 0 10px;

                span {
                    background-image: url(../images/owl-new-prev.svg);
                }
            }

            &.owl-next {
                right: 0;
                border-radius: 0 10px 10px 0;

                span {
                    background-image: url(../images/ow-new-next.svg);
                }
            }
        }
    }

    .owl-stage-outer {
        border-radius: 10px;
    }
}

.event-webinar {
    padding: 0 5px;
}

.event-webinar-text {
    max-width: 750px;
    width: 100%;
    order: 1;
    padding: 0 10px;

    p {
        line-height: 1.3;
        color: $gray-900;
        margin-bottom: 1.5rem;
    }

    @media screen and (max-width: 991px) {
        order: 2;
        p {
            margin-bottom: 1rem;
        }
    }
}

.wrap-event-webinar-img {
    max-width: 487px;
    width: 100%;
    padding: 0 10px;
    order: 2;
    @media screen and (max-width: 991px) {
        order: 1;
        max-width: none;
    }
}

.event-webinar-img {
    position: relative;
    width: 100%;
    padding-top: 66.6%;
    border-radius: 10px;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.event-webinar-video {
    max-width: 650px;
    padding: 0 40px 0 10px;
    width: 100%;
    order: 0;
    @media screen and (max-width: 991px) {
        padding: 0 10px;
        max-width: none;
    }
}

.event-webinar-data {
    p {
        margin-bottom: 1.25rem;
        color: $gray-600;
        line-height: 1.3;
    }
}

.main-data-event {
    .data-event {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.data-event {
    border-radius: 5px;
    padding: 9.5px 20px;
    background-color: $gray-400;
}

.data-text {
    line-height: 1.3;
}

.main-start-page {
    //min-height: calc(100vh - 300px);
    position: relative;
    z-index: 1;
    max-width: 725px;
    width: 100%;

    h2 {
        color: $white;
        margin-bottom: 1.875rem;
        letter-spacing: 0.02em;
        line-height: 1.3;
    }

    &.error {
        .error-text {
            opacity: 1;
        }

        .start-page-input {
            input {
                background-color: #FFD3D3;
            }
        }
    }
}

.error-text {
    color: $white;
    line-height: 1.3;
    transition: 0.3s;
    opacity: 0;
}

.start-page {
    position: relative;
    padding: 70px 0 120px 0;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        background-color: $black;
    }
}

.start-page-text {
    color: $gray-600;
    line-height: 1.3;
}

.start-page-input {
    max-width: 305px;
    width: 100%;

    p {
        color: $gray-600;
        font-size: 0.875rem;
        line-height: 1.3;
    }

    input {
        width: 100%;
        border-radius: 5px;
        background-color: $white;
        padding: 9px 13px;
        outline: none;
        font-size: 0.875rem;
        line-height: 1.3;
        color: $gray-900;
        transition: 0.3s;
        border: none;

        &.start-page-password {
            font-family: $Lato-Bold;
            font-size: 1.5rem;
            padding: 2.5px 13px;
        }
    }
}

.recording-webinar {
    padding: 10px 15px;
    border-radius: 5px;
    background-color: $gray-500;
}

.recording-webinar-text {
    font-size: 0.875rem;
    color: $gray-600;
    line-height: 1.3;
}

.recording-webinar-data {
    color: $gray-900;
    line-height: 1.3;
}

.contact-page {
    padding: 0 5px;
}

.contact-page-left {
    max-width: 580px;
    width: 100%;
    padding: 0 10px;
}

.main-contact-box {
    font-size: 1rem;
    line-height: 1.3;
    color: $gray-900;

    .ic {
        margin-bottom: 0.75rem;
    }

    .contact-link {
        margin-right: 0.25rem;
        margin-bottom: 0.75rem;
        max-width: calc(100% - 41px);
    }
}

.contact-link {
    font-size: 1rem;
    line-height: 1.3;
    color: $gray-900;
    display: inline-block;

    &:hover {
        color: $gray-900;
    }
}

.contact-page-right {
    max-width: 650px;
    padding: 0 10px;
    width: 100%;
}

.contact-question {
    border: 1px solid $gray-500;
    border-radius: 8px;
    overflow: hidden;
}

.contact-question-top {
    background-color: $gray-500;
    padding: 26px 30px;

    h3 {
        color: $gray-900;
        line-height: 1.3;
        margin-bottom: 0.5rem;
        font-family: $Lato-Bold;
    }

    p {
        color: $gray-700;
        line-height: 1.3;
        margin-bottom: 0;
    }
}

.contact-question-middle {
    padding: 40px 30px 50px 30px;

    .error {
        background-color: #FFD3D3;
    }

    .contact-question-input {
        max-width: 516px;
        margin-bottom: 1.875rem;
    }

    .contact-question-textarea {
        max-width: 516px;
    }
}

.contact-question-input {
    width: 100%;

    input {
        width: 100%;
        color: $gray-900;
        font-size: 1rem;
        line-height: 1.3;
        border: none;
        border-bottom: 1px solid $gray-500;
        outline: none;
        padding: 5px 0;

        &::-webkit-input-placeholder {
            color: $gray-600;
            transition: 0.3s;
        }

        &::-moz-placeholder {
            color: $gray-600;
            transition: 0.3s;
        }

        &:-moz-placeholder {
            color: $gray-600;
            transition: 0.3s;
        }

        &:-ms-input-placeholder {
            color: $gray-600;
            transition: 0.3s;
        }

        &:focus::-webkit-input-placeholder {
            color: transparent
        }

        &:focus::-moz-placeholder {
            color: transparent
        }

        &:focus:-moz-placeholder {
            color: transparent
        }

        &:focus:-ms-input-placeholder {
            color: transparent
        }
    }
}

.contact-question-textarea {
    width: 100%;

    textarea {
        width: 100%;
        color: $gray-900;
        font-size: 1rem;
        line-height: 1.3;
        border: none;
        border-bottom: 1px solid $gray-500;
        outline: none;
        padding: 0 0 19px 0;
        resize: none;
        margin-bottom: 0;

        &::-webkit-input-placeholder {
            color: $gray-600;
            transition: 0.3s;
        }

        &::-moz-placeholder {
            color: $gray-600;
            transition: 0.3s;
        }

        &:-moz-placeholder {
            color: $gray-600;
            transition: 0.3s;
        }

        &:-ms-input-placeholder {
            color: $gray-600;
            transition: 0.3s;
        }

        &:focus::-webkit-input-placeholder {
            color: transparent
        }

        &:focus::-moz-placeholder {
            color: transparent
        }

        &:focus:-moz-placeholder {
            color: transparent
        }

        &:focus:-ms-input-placeholder {
            color: transparent
        }
    }
}

.contact-question-bottom {
    padding: 0 30px 70px 30px;
}

.contact-page-map {
    border-radius: 8px;
    border: 1px solid $gray-500;
    overflow: hidden;
    position: relative;

    .wrap-select {
        position: absolute;
        left: 40px;
        top: 16px;
        @media screen and (max-width: 575px) {
            left: 20px;
        }
    }

}

.wrap-select {
    max-width: 451px;
    width: calc(100% - 80px);
    @media screen and (max-width: 575px) {
        width: calc(100% - 40px);
    }

}

.main-select {
    width: 100% !important;

    .dropdown-toggle {
        padding: 16px 20px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        background-color: $white !important;
        border: none;

        &:focus {
            outline: none !important;
        }

        .filter-option {
            .filter-option-inner-inner {
                color: $gray-900;
                font-size: 1rem;
                line-height: 1.3;
            }
        }

        &:after {
            width: 20px;
            height: 10px;
            background-image: url(../images/select-tick.svg);
            background-position: center center;
            background-repeat: no-repeat;
            -webkit-background-size: contain;
            background-size: contain;
            border: none;
        }
    }

    .dropdown-menu {
        padding: 0;
        border: none;
        max-width: 100%;

        li {
            a {
                padding: 10px 20px;
                max-width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;

                .text {
                    max-width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: inline-block;
                }

                &.active {
                    background-color: $gray-600;
                }

                &:focus {
                    outline: none;
                }

                &:active {
                    background-color: $gray-600;
                }
            }

        }
    }
}

.contact-page-page {
    .search-input {
        max-width: 470px;
    }
}

.wrap-contact-page {
    .main-box-list {
        padding: 60px 30px 40px 30px;
        margin-bottom: 1.25rem;

        .list-img {
            width: 274px;
            height: 260px;
            margin-top: -25px;
            margin-bottom: -5px;
        }

        .name-curses {
            left: 30px;
        }

        .main-box-list-text {
            max-width: 865px;
        }

        &:last-child {
            margin-bottom: 4.375rem;
        }

        @media screen and (max-width: 991px) {
            padding: 45px 10px 20px 10px;
            .name-curses {
                left: 10px;
            }
            .list-img {
                margin: 0 auto 1.5rem auto;
                max-width: 274px;
                width: 100%;
            }
        }
    }
}

.personal-account {
    padding-top: 80px;
    padding-bottom: 90px;
}

.personal-account-logo {
    img {
        width: 143px;
        height: 143px;
        border-radius: 100%;
        overflow: hidden;
        margin-bottom: 0.75rem;
        display: inline-block;
        object-fit: cover;
    }

    @media screen and (max-width: 575px) {
        img {
            width: 100px;
            height: 100px;
        }
    }
}

.personal-account-text-top {
    h4 {
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 20px;
        color: $gray-900;
        font-size: 0.875rem;
    }

    .personal-account-info {
        margin-bottom: 20px;

        p {
            &:first-child {
                color: $gray-900;
            }

            &:last-child {
                color: $gray-600;
            }
        }
    }
}

.account-top-left {
    max-width: 510px;
    width: 100%;
    padding: 0 10px;
}

.account-filter-btn {
    width: 163px;
}

.account-filter-file {
    position: relative;
    cursor: pointer;

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    button {
        background: none;
        outline: 0;
        border-radius: 8px;
        border: 1px solid rgba(228, 0, 15, 0.2);
        color: $red;
        line-height: 1.3;
        padding: 10.5px 16px;
        margin-bottom: 0;
        cursor: pointer;
    }

    a {
        border-radius: 8px;
        border: 1px solid rgba(228, 0, 15, 0.2);
        color: $red;
        line-height: 1.3;
        padding: 10.5px 16px;
        margin-bottom: 0;
        cursor: pointer;
        display: inline-block;
    }
}

.personal-account-top {
    padding: 0 5px;
}

.account-filter-collapse {
    padding: 0 5px;

    .btnText {
        font-size: 14px;
        line-height: 1.3;
        border-bottom: 1px dashed #E4000F;
        color: #E4000F;
        margin-left: auto;
        margin-right: 16px;
        align-self: flex-start;
        @media (max-width: 767px) {
            margin-right: auto;
        }
    }
}

.account-filter-box {
    padding: 0 10px;

    p {
        margin-bottom: 0.375rem;
        line-height: 1.3;
        font-size: 0.875rem;
        color: $gray-700;
    }

    .main-select {
        .dropdown-toggle {
            box-shadow: none;
            border: 1px solid $gray-500;
            border-radius: 5px;
            padding: 4.5px 10px;

            .filter-option {
                .filter-option-inner-inner {
                    font-size: 0.875rem;
                    color: $gray-900;
                }
            }

            &.bs-placeholder {
                .filter-option {
                    .filter-option-inner {
                        .filter-option-inner-inner {
                            color: transparent;
                        }
                    }
                }
            }

            &:after {
                width: 8px;
                height: 5px;
            }
        }
    }

    .dropdown-menu {
        li {
            a {
                padding: 6.5px 10px;

                .text {
                    font-size: 0.875rem;
                }
            }
        }

        .bs-searchbox {
            .form-control {
                border: 1px solid $gray-500;
                border-radius: 5px;
                padding: 4.5px 10px;
                font-size: 0.875rem;
                outline: none;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }
}

.account-filter-search {
    border-bottom: 1px solid $gray-500;
    font-size: 0.875rem;
    padding: 5px 0;
    line-height: 1.3;

    .search-ic {
        width: 14px;
        height: 14px;
    }

    input {
        width: calc(100% - 20px);
        border: none;
        outline: none;
        font-size: 0.875rem;
        color: $gray-900;

        &::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }

        &::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }

        &::-webkit-input-placeholder {
            color: $gray-700;
            transition: 0.3s;
        }

        &::-moz-placeholder {
            color: $gray-700;
            transition: 0.3s;
        }

        &:-moz-placeholder {
            color: $gray-700;
            transition: 0.3s;
        }

        &:-ms-input-placeholder {
            color: $gray-700;
            transition: 0.3s;
        }

        &:focus::-webkit-input-placeholder {
            color: transparent
        }

        &:focus::-moz-placeholder {
            color: transparent
        }

        &:focus:-moz-placeholder {
            color: transparent
        }

        &:focus:-ms-input-placeholder {
            color: transparent
        }
    }
}

.personal-account-tabs {
    h3 {
        color: $gray-900;
    }

    .nav.nav-tabs.nav-tabs-list {
        max-width: none;
        margin-right: 0;

        .nav-item.nav-item-list {
            .nav-link.nav-link-list {
                height: 100%;
                cursor: pointer;
                color: $gray-900;
                padding: 10px 0;
                text-align: center;
                font-family: $Lato-Semibold;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-height: 60px;

                .main-personal-box {
                    position: relative;
                    padding: 0 22px;
                    text-align: left;

                    .wrap-bg-circle {
                        left: 4px;
                    }
                }

                &.active {
                    color: $white;

                    .wrap-bg-circle {
                        .bg-circle {
                            height: 2700px;
                            width: 2700px;
                        }
                    }
                }
            }

            //@media screen and (max-width: 991px) {
            //  &:nth-child(1) {
            //    width: 33.3%;
            //  }
            //  &:nth-child(2) {
            //    width: 33.3%;
            //  }
            //  &:nth-child(3) {
            //    width: 33.3%;
            //  }
            //  &:nth-child(4) {
            //    width: 33.3%;
            //  }
            //  &:nth-child(5) {
            //    width: 33.3%;
            //  }
            //  &:nth-child(6) {
            //    width: 33.3%;
            //  }
            //  &:nth-child(7) {
            //    width: 33.3%;
            //  }
            //}
            //@media screen and (max-width: 767px) {
            //  &:nth-child(1) {
            //    width: 50%;
            //  }
            //  &:nth-child(2) {
            //    width: 50%;
            //  }
            //  &:nth-child(3) {
            //    width: 50%;
            //  }
            //  &:nth-child(4) {
            //    width: 50%;
            //  }
            //  &:nth-child(5) {
            //    width: 50%;
            //  }
            //  &:nth-child(6) {
            //    width: 50%;
            //  }
            //  &:nth-child(7) {
            //    width: 50%;
            //  }
            //}
            //@media screen and (max-width: 490px) {
            //  &:nth-child(1) {
            //    width: 100%;
            //  }
            //  &:nth-child(2) {
            //    width: 100%;
            //  }
            //  &:nth-child(3) {
            //    width: 100%;
            //  }
            //  &:nth-child(4) {
            //    width: 100%;
            //  }
            //  &:nth-child(5) {
            //    width: 100%;
            //  }
            //  &:nth-child(6) {
            //    width: 100%;
            //  }
            //  &:nth-child(7) {
            //    width: 100%;
            //  }
            //}
        }
    }
}

.personal-account-th-1 {
    padding: 0 5px;
    max-width: 530px;
    width: 100%;
    position: relative;

    .personal-th-logo {
        margin-right: 0.75rem;
    }

    .btn-gray-tick {
        margin-left: 75px;
        text-align: left;
    }

    @media screen and (max-width: 1199px) {
        max-width: 293px;
        //.btn-gray-tick{
        //  margin-left: 0;
        //}
        //.personal-th-logo{
        //  position: absolute;
        //  margin-right: 0;
        //  left: 5px;
        //  top: 0;
        //}
        //.personal-th-text-bottom{
        //  padding: 0;
        //}
        //.personal-th-text{
        //  h5{
        //    margin-left: 63px;
        //  }
        //}
    }
    @media screen and (max-width: 991px) {
        max-width: none;
        padding: 0;
        margin-bottom: 1rem;
        .btn-gray-tick {
            margin-left: 0;
        }
    }
}

.personal-account-th-2 {
    width: 100%;
    max-width: 175px;
    min-width: 70px;
    padding: 0 5px;
    border-right: 1px solid transparent;
    @media screen and (max-width: 575px) {
        max-width: none;
        min-width: auto;
        width: 100%;
        font-size: 0.875rem;
        position: relative;
        padding: 10px 0 10px 85px !important;
        margin-bottom: 0 !important;
        text-align: right;
        &:before {
            content: 'Дата';
            color: $gray-600;
            font-size: 0.625rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
    }
}

.personal-account-th-3 {
    width: 100%;
    max-width: 72px;
    min-width: 44px;
    padding: 0 5px;
    border-right: 1px solid transparent;
    @media screen and (max-width: 575px) {
        max-width: none;
        min-width: auto;
        width: 100%;
        font-size: 0.875rem;
        position: relative;
        padding: 10px 0 10px 85px !important;
        margin-bottom: 0 !important;
        text-align: right;
        &:before {
            content: 'Время';
            color: $gray-600;
            font-size: 0.625rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
    }
}

.personal-account-th-4 {
    width: 100%;
    max-width: 128px;
    min-width: 125px;
    padding: 0 5px;
    border-right: 1px solid transparent;
    @media screen and (max-width: 767px) {
        min-width: 70px;
    }
    @media screen and (max-width: 575px) {
        max-width: none;
        min-width: auto;
        width: 100%;
        font-size: 0.875rem;
        position: relative;
        padding: 10px 0 10px 85px !important;
        margin-bottom: 0 !important;
        text-align: right;
        &:before {
            content: 'Пройдено тестов';
            color: $gray-600;
            font-size: 0.625rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
    }
}

.personal-account-th-5 {
    width: 100%;
    max-width: 114px;
    min-width: 85px;
    padding: 0 5px;
    @media screen and (max-width: 575px) {
        max-width: none;
        min-width: auto;
        width: 100%;
        font-size: 0.875rem;
        position: relative;
        padding: 10px 0 10px 85px !important;
        margin-bottom: 0 !important;
        text-align: right;
        &:before {
            content: 'Успешность';
            color: $gray-600;
            font-size: 0.625rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
        .success-box {
            text-align: center;
        }
    }
}

.personal-account-th-6 {
    width: 100%;
    max-width: 150px;
    min-width: 125px;
    padding: 0 5px;
    @media screen and (max-width: 767px) {
        min-width: 40px;
        max-width: 40px;
    }
    @media screen and (max-width: 575px) {
        max-width: none;
        min-width: auto;
        width: 50%;
        padding: 0;
    }
}

.personal-account-th-7 {
    width: 100%;
    max-width: 190px;
    min-width: 178px;
    padding: 0 5px;
    @media screen and (max-width: 767px) {
        min-width: 170px;
    }
    @media screen and (max-width: 575px) {
        max-width: none;
        min-width: auto;
        width: 50%;
        padding: 0;
    }
}

.personal-account-th-last {
    max-width: 340px;
    width: 100%;
    min-width: 303px;
    padding: 11px 5px;
    text-align: right;

    .btn-black-dashed {
        margin-right: 30px;
        font-size: 1rem;
    }

    @media screen and (max-width: 767px) {
        min-width: 210px;
        text-align: right;
        .btn-black-dashed {
            margin-right: 0;
        }
    }
    @media screen and (max-width: 575px) {
        padding: 11px 0;
        text-align: center;
        max-width: none;
    }
}

.success-box {
    display: inline-block;
    max-width: 71px;
    width: 100%;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border: 1px solid transparent;
        border-radius: 5px;
        width: 100%;
        height: 32px;
    }

    &.full-box {
        &:after {
            border: 1px solid $gray-700;
        }

    }
}

.personal-account-table-h {
    padding: 0 0 0 5px;
    font-size: 0.875rem;
    line-height: 1.3;
    color: $gray-600;
    text-align: center;
    @media screen and (max-width: 1199px) {
        padding: 0;
    }

    .personal-account-th-7 {
        padding-right: 0;
        text-align: right;
    }

    @media screen and (max-width: 991px) {
        display: none !important;
    }
}

.main-accordion {
    .main-card {
        margin-top: -1px;

        &:first-child {
            .main-wrap-card {
                .personal-account-table {
                    &.collapsed {
                        &:before {
                            content: '';
                            position: absolute;
                            top: -1px;
                            left: 5px;
                            width: calc(100% - 10px);
                            height: 1px;
                            background-color: $gray-500;
                        }
                    }
                }
            }
        }
    }
}

.main-wrap-card {
    .personal-account-th-1 {
        padding-right: 45px;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
            width: 13px;
            height: 7px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(../images/select-tick.svg);
            //background-image: url(../img/select-tick.svg);
            transition: 0.3s;
            opacity: 0;
        }

        @media screen and (max-width: 1199px) {
            padding-right: 20px;
            &:after {
                right: 0;
            }
        }

    }

    @media screen and (max-width: 575px) {
        .personal-account-table {
            .table-info-accordion-box {
                display: none !important;
            }
        }
    }
}

.table-info-accordion-block {
    text-align: center;
    font-size: 0.875rem;
    color: $gray-900;
    line-height: 1.3;
    background-color: $gray-400;

    .personal-account-th-2 {
        padding-top: 13.5px;
        padding-bottom: 13.5px;
        border-right: 1px solid $gray-500;
    }

    .personal-account-th-3 {
        padding-top: 13.5px;
        padding-bottom: 13.5px;
        border-right: 1px solid $gray-500;
        @media screen and (max-width: 575px) {
            border-right: 1px solid transparent;
        }
    }

    .personal-account-th-4 {
        padding-top: 13.5px;
        padding-bottom: 13.5px;
        border-right: 1px solid $gray-500;
    }

    .personal-account-th-5 {
        padding-top: 13.5px;
        padding-bottom: 13.5px;
    }

    .personal-account-th-6 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .personal-account-th-7 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .name-table-box {
        display: inline-block;
        margin-right: 30px;
    }

    .personal-account-th-7 {
        text-align: right;
    }

    @media screen and (max-width: 575px) {
        flex-wrap: wrap;
    }
}

.table-info-accordion-box {
    max-width: 745px;
    width: 100%;

    .table-info-accordion-block {
        &:first-child {
            background-color: transparent;

            .personal-account-th-2 {
                padding-top: 6.5px;
                padding-bottom: 18px;
            }

            .personal-account-th-3 {
                padding-top: 6.5px;
                padding-bottom: 18px;
            }

            .personal-account-th-4 {
                padding-top: 6.5px;
                padding-bottom: 18px;
            }

            .personal-account-th-5 {
                padding-top: 6.5px;
                padding-bottom: 18px;
            }

            .personal-account-th-last {
                padding-top: 6.5px;
                padding-bottom: 18px;
            }
        }

        &:nth-child(2) {
            border-radius: 5px 5px 0 0;

            .personal-account-th-2 {
                padding-top: 21px;
            }

            .personal-account-th-3 {
                padding-top: 21px;
            }

            .personal-account-th-4 {
                padding-top: 21px;
            }

            .personal-account-th-5 {
                padding-top: 21px;
            }

            .personal-account-th-last {
                padding-top: 21px;
            }
        }

        &:last-child {
            border-radius: 0 0 5px 5px;
            margin-bottom: 1.5rem;

            .personal-account-th-2 {
                padding-bottom: 28px;
            }

            .personal-account-th-3 {
                padding-bottom: 28px;
            }

            .personal-account-th-4 {
                padding-bottom: 28px;
            }

            .personal-account-th-5 {
                padding-bottom: 28px;
            }

            .personal-account-th-last {
                padding-bottom: 28px;
            }
        }
    }

    @media screen and (max-width: 575px) {
        flex-wrap: wrap;
        .personal-account-th-2 {
            margin-bottom: 0.75rem;
            border-right: none;
        }
        .personal-account-th-3 {
            margin-bottom: 0.75rem;
            border-right: none;
        }
        .personal-account-th-4 {
            margin-bottom: 0.75rem;
            border-right: none;
        }
        .personal-account-th-5 {
            margin-bottom: 0.75rem;
            border-right: none;
        }
        .personal-account-th-6 {
            margin-bottom: 0.75rem;
            border-right: none;
        }
        .personal-account-th-7 {
            text-align: center !important;
            border-right: none;
        }
    }
}

.table-info-accordion {
    padding-left: 5px;
    @media screen and (max-width: 991px) {
        padding-left: 0;
    }
    @media screen and (max-width: 575px) {
        .table-info-accordion-box {
            height: auto;

            .table-info-accordion-block {
                display: none !important;

                &:first-child {
                    display: flex !important;
                }
            }
        }
    }
}

.personal-account-table {
    padding: 16px 0 20px 5px;
    text-align: center;
    color: $gray-900;
    font-size: 0.875rem;
    border: 1px solid transparent;
    transition: 0.3s;
    cursor: pointer;
    position: relative;

    &.collapsed {
        .personal-th-text {
            h5 {
                background-color: transparent;
            }
        }

        .personal-account-th-1 {
            &:after {
                opacity: 0;
            }
        }

        &:hover {
            border-radius: 5px;
            border: 1px solid $gray-500;
            background-color: $gray-400;
            //h5 {
            //
            //}
            .personal-account-th-1 {
                &:after {
                    opacity: 1;
                }
            }
        }


    }

    .personal-th-text {
        h5 {
            background-color: $gray-400;
        }
    }

    &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 5px;
        width: calc(100% - 10px);
        height: 1px;
        background-color: $gray-500;
    }

    .personal-account-th-1 {
        text-align: left;
    }

    .personal-account-th-7 {
        text-align: right;

        .name-table-box {
            display: inline-block;
            text-align: center;
            max-width: 157px;
            width: 100%;
        }
    }

    @media screen and (max-width: 991px) {
        flex-wrap: wrap;
        padding: 16px 0 20px 0;
    }
}

.personal-th-logo {
    width: 51px;
    height: 51px;
    border-radius: 100%;
    object-fit: cover;
    border: 1px solid $gray-700;
}

.personal-th-text {
    h5 {
        color: $gray-900;
        padding: 0.25rem 0.75rem;
        border-radius: 5px;
        margin-bottom: 0.25rem;
        transition: 0.3s;
    }

    @media screen and (max-width: 991px) {
        width: calc(100% - 63px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        h5 {
            max-width: 48%;
        }
        .personal-th-text-bottom {
            width: 48%;
            padding: 0;
        }
    }
    @media screen and (max-width: 575px) {
        flex-wrap: wrap;
        h5 {
            max-width: none;
        }
        .personal-th-text-bottom {
            width: 100%;
            padding: 0 0.75rem;
        }
    }
}

.personal-th-text-bottom {
    padding: 0 0.75rem;
    line-height: 1.3;
}

.company-text {
    color: $gray-900;
    font-family: $Lato-Semibold;
}

.table-info-accordion-tr {
    .table-info-accordion-box {
        height: 40px;
        overflow: hidden;
        display: block !important;
        transition: 0.3s;

        &.show {
            height: auto;
            overflow: visible;
        }

        @media screen and (max-width: 575px) {
            height: auto !important;
        }
    }

    @media screen and (max-width: 991px) {
        flex-wrap: wrap;
        margin-bottom: 1rem;
    }
}

.account-filter-top {
    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: center !important;
    }
}

.account-filter-top-left {
    @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: space-around;
        flex-wrap: wrap;
    }
}

.account-top-right {
    padding: 0 10px;
    max-width: 650px;
    width: 100%;
}

.wrap-certificates {
    padding-left: 0;

    li {
        padding: 20px 0 25px 0;
        border-bottom: 1px solid $gray-500;

        .btn-black-dashed {
            display: initial;
            line-height: 1.5;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

.main-certificates {
    // display: block !important;
    height: auto !important;
    overflow: visible !important;

    .wrap-certificates {
        li {
            .btn-black-dashed {
                border-bottom: 1px dashed $gray-650;
                padding-bottom: 3px;
            }

            &:nth-child(1) {
                height: auto;
                overflow: visible;
                opacity: 1;
                padding: 20px 0;
            }

            &:nth-child(2) {
                height: auto;
                overflow: visible;
                opacity: 1;
                padding: 20px 0;
            }

            &:nth-child(3) {
                height: auto;
                overflow: visible;
                opacity: 1;
                padding: 20px 0;
            }

            overflow: hidden;
            height: 0;
            padding: 0;
            opacity: 0;
            transition: 0.3s;
        }

    }

    &.show {
        .wrap-certificates {
            li {
                height: auto;
                overflow: visible;
                opacity: 1;
                padding: 20px 0;
            }
        }
    }

    &.collapsing {

        .wrap-certificates {
            li {
                height: auto;
                overflow: visible;
                opacity: 1;
                padding: 20px 0;
            }
        }
    }
}

.personal-account-info {
    p {
        font-size: 0.875rem;
        color: $gray-900;
        margin-bottom: 0;
        line-height: 1.5;

        &:first-child {
            color: $gray-600;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.personal-courses-top {
    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        h3 {
            width: 100%;
            order: 1;
        }
        .link-arrow-red {
            order: 0;
        }
    }
}

.certificates-quantity {
    display: inline-block;
}

.personal-account-text-bottom {
    padding: 20px 0;
    border-top: 1px solid $gray-500;

    .personal-account-info {
        margin-bottom: 0.75rem;

        p {
            font-size: 0.875rem;
            line-height: 1.3;
            margin-bottom: 0.25rem;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.box-personal-courses {
    .main-box-list {
        margin-bottom: 20px;
        padding: 65px 30px 25px 30px;

        .name-curses {
            left: 30px;
        }

        .next-webinar-text {
            left: 30px;
        }

        .list-img {
            width: 275px;
            height: 260px;
            margin-top: -32px;
            margin-bottom: 7px;
        }

        .main-box-list-text {
            max-width: none;
            width: calc(100% - 350px);
        }

        &:last-child {
            margin-bottom: 0;
        }

        @media screen and (max-width: 991px) {
            padding: 45px 10px 20px 10px;
            .main-box-list-text {
                max-width: none;
                width: 100%;
            }
            .list-img {
                margin: 0 auto 1.5rem auto;
                max-width: 275px;
                width: 100%;
            }
            .name-curses {
                left: 20px;
                top: 15px;
            }

            .next-webinar-text {
                left: 20px;
                top: 15px;
            }
        }

    }
}

.main-personal-courses {
    .wrap-personal-courses {
        margin-bottom: 60px;

        .box-personal-courses {
            .main-block-list {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.certificates-none {
    color: $gray-700;
    line-height: 1.3;
    padding-left: 2rem;
}

.privacy-policy {
    p {
        margin-bottom: 1.25rem;
        line-height: 1.3;
    }

    h4 {
        margin-bottom: 1.25rem;
        font-family: $Lato-Bold;
    }
}

.mistake-block {
    position: relative;
    max-width: 844px;
    padding-top: 28.5%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media screen and (max-width: 767px) {
        padding-top: 43%;
    }
}

.main-mistake {
    h3 {
        font-size: 1.625rem;
    }
}

//modal
.consent-processing {
    border-radius: 0;

    .modal-body {
        padding: 50px 100px;

        p {
            line-height: 1.3;
        }

        @media screen and (max-width: 575px) {
            padding: 30px;
        }
    }
}

.success-statistics {
    border-radius: 0;

    .modal-body {
        padding: 40px 15px 60px 40px;
        position: relative;

        h3 {
            padding-right: 25px;
        }

        h4 {
            padding-right: 25px;
        }

        @media screen and (max-width: 575px) {
            padding: 30px 10px 30px 10px;
        }
    }
}

.close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 12px;
    height: 15px;
    background-image: url(../images/close-modal.svg);
    opacity: 1;
}

.block-success-statistics {
    line-height: 1.3;
    padding-right: 20px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 4px;
        right: 4px;
        width: 12px;
        height: 12px;
        background-position: center center;
        -webkit-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../images/cross-red.svg);
    }

    &.traversed {
        &:after {
            width: 19px;
            height: 14px;
            top: 3px;
            right: 0;
            background-image: url(../images/green-tick.svg);
        }
    }

    p {
        margin-bottom: 0;
        color: $gray-900;
        max-width: calc(100% - 36px);
        text-align: left;
    }
}

.wrap-success-statistics {
    list-style: none;
    padding-left: 0;
    padding-right: 60px;
    margin-bottom: 0;

    .block-success-statistics {
        margin-bottom: 1.125rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: 767px) {
        padding-right: 15px;
    }
}

.number-success-statistics {
    width: 36px;
    padding-right: 6px;
}

.main-success-statistics {
    max-height: 410px;
    overflow: auto;
}

.mCSB_inside > .mCSB_container {
    margin-right: 10px;
}

.mCSB_scrollTools {
    width: 10px;
    opacity: 1;
}

.mCSB_scrollTools .mCSB_draggerRail {
    width: 100%;
    border-radius: 10px;
    background-color: $gray-500;
    opacity: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: $red !important;
    width: 100%;
    border-radius: 10px;
}

.success-statistics-bottom {
    .btn-red {
        margin-right: 40px;
    }
}

.modal-mini {
    max-width: 400px;
}

.small-modal {
    border-radius: 0;

    .modal-body {
        padding: 40px 45px 25px 45px;

        @media screen and (max-width: 480px) {
            padding: 30px;
        }
    }
}

.presentation-modal {

    border-radius: 0;

    .modal-body {
        padding: 40px 45px 25px 45px;

        @media screen and (max-width: 480px) {
            padding: 30px;
        }
    }
}

.wrap-btn-small {
    .btn-red {
        max-width: 170px;
        width: 100%;
        color: #ffffff;
        &:hover{
            color: #ffffff;
        }
    }
}

.rating-modal-success {
    width: 133px;
    text-align: center;
    line-height: 1.3;
    padding: 0 5px;
    @media screen and (max-width: 767px) {
        width: 90px;
    }
    @media screen and (max-width: 480px) {
        width: 55px;
    }
}

.rating-modal-test {
    width: 185px;
    text-align: center;
    line-height: 1.3;
    padding: 0 5px;
    @media screen and (max-width: 767px) {
        width: 122px;
    }
    @media screen and (max-width: 480px) {
        width: 60px;
    }
}

.rating-modal-top {
    padding: 7px 0 7px 5px;
    border-radius: 5px;
    background-color: $gray-500;
    margin-right: 25px;

    p {
        color: $gray-700;
        line-height: 1.3;
        font-size: 0.875rem;
        margin-bottom: 0;
    }

    @media screen and (max-width: 767px) {
        margin-right: 0;
    }
    @media screen and (max-width: 480px) {
        display: none !important;
    }
}

.rating-modal-box {
    margin-right: 15px;

    .rating-modal-success {
        border-right: 1px solid $gray-500;
        padding-bottom: 20px;
    }

    p {
        padding-bottom: 20px;
    }

    .rating-modal-test {
        padding-bottom: 20px;
    }

    @media screen and (max-width: 767px) {
        margin-right: 0;
        p {
            word-break: break-word;
        }
    }

}

.main-rating-modal {
    max-height: 500px;
    overflow: auto;

    .wrap-success-statistics {
        padding-top: 20px;
    }

    @media screen and (max-width: 767px) {
        max-height: 65vh;
    }
}

.rating-modal-h {
    margin-right: 25px;
    @media screen and (max-width: 767px) {
        margin-right: 10px;
    }
}

.manager-card {
    border-radius: 0;

    .modal-body {
        padding: 50px 40px 75px 40px;
        @media screen and (max-width: 767px) {
            padding: 15px;
        }
    }
}

.manager-card-logo {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 100%;
    @media screen and (max-width: 767px) {
        width: 100px;
        height: 100px;
    }
}

.manager-card-h-text {
    .personal-account-text-bottom {
        max-width: none;
        width: 100%;
    }
}

.manager-card-info {
    p {
        margin-bottom: 0.25rem;
        line-height: 1.3;

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        &:hover {
            color: $gray-900;
        }
    }
}

.manager-card-certificates {
    border-top: 1px solid $gray-500;

    ul {
        padding-left: 0;

        li {
            line-height: 1.3;
            font-size: 0.875rem;
            position: relative;
            padding-left: 28px;
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 4px;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background-color: $gray-600;

            }
        }
    }
}

.level-text-left {
    width: 50%;
    color: $gray-600;
}

.level-text-right {
    width: 50%;
}

.manager-level-line {
    width: 100%;
    height: 10px;
    position: relative;
    border-radius: 20px;
    background-color: $gray-500;
}

.manager-card-level {
    width: 100%;
    max-width: 470px;
}

.manager-level-slide {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    background-color: $gray-700;
}

.wrap-manager-card-level {
    .manager-card-level {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.editing-profile-h {
    padding-bottom: 0.5rem;

    .personal-account-info {
        margin-bottom: 1rem;

        p {
            font-size: 1rem;

            &:first-child {
                font-size: 0.875rem;
            }
        }
    }
}

.editing-profile {
    border-radius: 0;

    .modal-body {
        padding: 30px 60px 55px 40px;

        .link-arrow-red {
            font-size: 1.125rem;
        }

        @media screen and (max-width: 480px) {
            padding: 30px 15px;
        }
    }
}

.editing-profile-f {
    border-top: 1px solid $gray-500;
    padding: 30px 0 40px 0;
}

.editing-profile-input {
    max-width: 400px;
    width: 100%;

    p {
        color: $gray-600;
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
        line-height: 1.3;
    }

    input {
        padding: 5.5px 10px;
        border-radius: 5px;
        border: 1px solid $gray-600;
        font-size: 0.875rem;
        color: $gray-900;
        outline: none;
        width: 100%;
        margin-bottom: 0;
    }
}

.editing-profile-f {
    .editing-profile-input {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.feedback-modal {
    border-radius: 0;

    .modal-body {
        padding: 50px 40px 70px 40px;
        @media screen and (max-width: 575px) {
            padding: 30px 15px;
        }
    }
}

.glyphicon-star-empty {
    background-image: url(../images/star.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 1;
    font-size: 0;
}

.glyphicon-star {
    background-image: url(../images/star-active.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 1;
    font-size: 0;
}

.box-feedback-modal {

    p {
        margin-bottom: 0.5rem;
        line-height: 1.3;
        font-size: 0.875rem;
        color: $gray-700;
    }

    .caption {
        display: none;
    }

    .rating-container {
        margin-left: -2.5px;
        font-size: 0;

        .clear-rating {
            padding-right: 0;
        }

        .rating-input {
            height: 0;
        }

        .star {
            margin: 0 2.5px;
            font-size: 0;
        }
    }
}

.feedback-modal-textarea {
    p {
        color: $gray-900;
        line-height: 1.3;
        margin-bottom: 0.5rem;
    }

    textarea {
        border-radius: 5px;
        border: 1px solid $gray-700;
        padding: 10px;
        resize: none;
        width: 100%;
    }
}

.modal-mini {
    max-width: 480px;
}

.birthday-modal {
    padding: 30px 40px 40px 40px;
    border-radius: 0;
    background-color: #F5F5F5;

    .modal-body {
        padding: 30px 40px 40px 40px;
        border-radius: 8px;
        background-color: $white;

        .close {
            right: -27px;
            top: -18px;
        }
    }

    @media screen and (max-width: 480px) {
        padding: 20px;
        .modal-body {
            padding: 20px;

            .close {
                right: -16px;
            }
        }
    }
}

.birthday-img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.fancybox-opened {
    max-width: 1340px;
    width: 100% !important;
    height: auto !important;
    padding: 30px;
    left: 50% !important;
    transform: translateX(-50%);
}

.fancybox-inner {
    max-width: 1280px;
    width: 100% !important;
    padding-top: 61%;
    position: relative;
    height: auto !important;

    .fancybox-image {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.fancybox-opened {
    .fancybox-skin {
        box-shadow: none;
        border-radius: 10px;
    }
}

.fancybox-lock {
    .fancybox-overlay {
        overflow-y: auto;
    }
}

.fancybox-overlay {
    background-image: none;
    background-color: rgba(0, 0, 0, 0.5);
}

.fancybox-close {
    background-image: url(../images/cross-white.svg);
    width: 20px;
    height: 20px;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    top: -31px;
    right: -26px;
}

.my-courses-h {
    display: none;
    padding-top: 10.5px;

    h3 {
        font-family: $Lato-Bold;
    }

    &.active {
        display: flex;
    }
}

.my-courses-bgc {
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.success-statistics-h {
    padding-right: 70px;

    .personal-account-info {
        p {
            font-size: 1rem;
            line-height: 1.3;
        }
    }

    @media screen and (max-width: 767px) {
        padding-right: 25px;
    }
}

.modal-content {
    border: none;
}

.best-account {
    width: 100%;
    padding: 13px 10px;
    color: $red;
    line-height: 1.3;
    font-size: 1.125rem;
    text-align: center;
    font-family: $Lato-Medium;
    border-radius: 5px;
    background-color: $gray-500;
}

.main-presentation {
    iframe {
        height: 367px;
    }
}

.main-box-videos {
    h4 {
        color: $gray-900;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 1.3;
    }

    p {
        color: $gray-700;
        line-height: 1.3;
        font-size: 12px;
        margin-bottom: 0;
    }

    .wrap-box-videos {
        margin-bottom: 9px;
    }

    .tab-pane {
        margin-bottom: 30px;
    }
}

.bgImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.wrap-box-videos {
    height: 244px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        z-index: 1;
        pointer-events: none;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 80px;
        height: 80px;
        background-image: url(../images/play-video.svg);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
        z-index: 2;
        pointer-events: none;
    }

    &.playVideo {
        &:after {
            visibility: visible;
            opacity: 1;
        }

    }
}

.crown-h {
    position: relative;
    padding-left: 28px;
    font-size: 16px;
    font-family: 'Lato-Regular';
    line-height: 19px;

    &:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: 19px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-image: url(../images/crown-h.svg);
    }
}

.wrapUseRating {

    .accordion-btn-certificates {
        &:after {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.wrapUseRatingCollapse {

    @media screen and (max-width: 480px) {
        margin-left: -8px;
        margin-right: -8px;
        width: calc(100% + 16px) !important;
        .mainUseRatingAccordion {
            padding: 0 8px;
        }
    }
}

.mainUseRatingAccordion {
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    padding: 0 16px;
    counter-reset: myCounter;

    .accordion {
        border-bottom: 1px solid #E0E0E0;

        &:last-child {
            border-bottom: none;
        }

        .mainPersonalRating {
            .ratingPersonal {
                &:before {
                    counter-increment: myCounter;
                    content: counter(myCounter);
                    display: inline-block;
                }

            }
        }

        &:nth-child(1) {
            .mainPersonalRating {
                .ratingPersonal {
                    &:after {
                        background-image: url(../images/top1.svg);
                    }

                }
            }
        }

        &:nth-child(2) {
            .mainPersonalRating {
                .ratingPersonal {
                    &:after {
                        background-image: url(../images/top2.svg);
                    }

                }
            }
        }

        &:nth-child(3) {
            .mainPersonalRating {
                .ratingPersonal {
                    &:after {
                        background-image: url(../images/top3.svg);
                    }

                }
            }
        }

        .collapse.show {
            .wrapPersonalRating {
                background-color: #F7F7F7;
            }
        }
    }

}

.mainWrapAccordionPersonal {
    padding-top: 20px;

    .placeRating {
        margin-bottom: 30px;
    }

    .mainBoxAccordionPersonal {
        border-bottom: 1px solid #E0E0E0;
        margin-bottom: 20px;

        &:last-child {
            border-bottom: none;
        }

        .wrapUseRatingCollapse {
            padding-bottom: 20px;

        }
    }

    .accordion-btn-certificates {
        &:not(.collapsed) {
            font-size: 0;

            &:before {
                content: 'Скрыть';
                font-size: 16px;
            }
        }
    }
}

.placeRating {
    position: relative;
    font-size: 16px;
    line-height: 1.2;

    .placeRatingCount {
        padding-right: 6px;
        color: #E4322B;
    }
}

.mainPersonalRating {
    position: relative;
    padding: 5px 65px 5px 20px;

    .accordion-btn-certificates {
        position: absolute;
        top: 11px;
        right: 0;

        &:before {
            display: none;
        }

        &:not(.collapsed) ~ .wrapPersonalRating {
            background-color: #F7F7F7;
        }
    }

    .ratingPersonal {
        position: absolute;
        top: 14px;
        left: 4px;
        z-index: 1;
    }
}

.wrapPersonalRating {
    position: relative;
    padding: 5px 8px;
    border-radius: 8px;
    max-width: 514px;
    transition: 0.3s;

    h4 {
        letter-spacing: 0.02em;
        line-height: 22px;
        margin-bottom: 6px;
    }
}

.wrapPersonalRatingInfo {
    font-size: 12px;
    line-height: 1.21;
    margin-left: -28px;
    width: calc(100% + 28px) !important;

    .text {
        margin-right: 10px;
        color: #9D9D9D;
        width: calc(50% - 5px);
        min-width: calc(50% - 5px);
    }
    @media screen and (max-width: 575px) {
flex-wrap: wrap;
        .text {
            margin-right: 0;
            width: 100%;
            min-width: 100%;
        }
    }
}

.ratingPersonal {
    letter-spacing: 0.02em;
    font-size: 12px;
    line-height: 14px;
    padding-right: 13px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 1px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 11px;
        height: 11px;
    }
}

.countPersinal {
    padding-right: 12px;
    position: relative;
    font-size: 16px;
    line-height: 1.3;
    color: #000000;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 4px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 10px;
        height: 13px;
        background-image: url(../images/countPersinal.svg);
    }
}

.boxDirectionRating {

    .wrapDirectionCheck {
        position: relative;
        padding-left: 25px;
        margin-bottom: 18px;
        align-self: center;
        line-height: 1.2;

        .directionCheck {
            position: absolute;
            top: 5px;
            left: 0;
            width: 8px;
            height: 8px;
            border-radius: 100%;
        }
    }

    .accordion-btn-certificates {
        margin-bottom: 18px;
    }
}

.colored-circle {
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.inline-block {
    display: inline-block;
}

.pointer-events-none {
    pointer-events: none;
}

.main-scroll {
    scrollbar-width: thin;
    scrollbar-color: #E4000F #E8E8E8;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #E8E8E8;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E4000F;
        border-radius: 10px;
    }
}

//modal
@import "import-scss/media";
