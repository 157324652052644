//font
h1 {
    font-size: 3.125rem;
    font-family: $Lato-Bold;
    @media screen and (max-width: 767px) {
        font-size: 2.25rem;
    }
}

h2 {
    font-size: 2.25rem;
    font-family: $Lato-Bold;
    @media screen and (max-width: 767px) {
        font-size: 1.75rem;
    }
}

h3 {
    font-size: 1.5rem;
    @media screen and (max-width: 767px) {
        font-size: 1.25rem;
    }
}

h4 {
    font-size: 1.25rem;
    font-family: $Lato-Semibold;
    @media screen and (max-width: 767px) {
        font-size: 1.125rem;
    }
}

h5 {
    font-size: 1.125rem;
    font-family: $Lato-Semibold;
    @media screen and (max-width: 767px) {
        font-size: 1rem;
    }
}

.fz-14 {
    font-size: $fs-14;
}

.color-red {
    color: $red;
}

.ff-bold {
    font-family: $Lato-Bold;
}

.color-gray-7 {
    color: $gray-700;
}

.color-black {
    color: $gray-900;
}

//font

//icon
.ic {
    background-position: center center;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
}

.shop-ic {
    background-image: url(../../images/shop-ic.svg);
    width: 19px;
    height: 19px;
}

.search-ic {
    background-image: url(../../images/search-ic.svg);
    width: 20px;
    height: 25px;
    display: inline-block;
}

.exit-h {
    background-image: url(../../images/exit-h.svg);
    width: 17px;
    height: 22px;
    display: inline-block;
}

.link-up {
    cursor: pointer;
    background-image: url(../../images/link-up.svg);
    width: 40px;
    height: 40px;
    display: inline-block;
}

.inst-ic {
    &:after {
        background-image: url(../../images/inst-ic.svg);
        width: 22px;
        height: 22px;
    }
}

.fb-ic {
    &:after {
        width: 11px;
        height: 20px;
        background-image: url(../../images/fb-ic.svg);
    }
}

.vk-ic {
    &:after {
        background-image: url(../../images/vk-ic.svg);
        width: 24px;
        height: 15px;
    }
}

.yt-ic {
    &:after {
        background-image: url(../../images/yt-ic.svg);
        width: 28px;
        height: 23px;
    }
}

.nav-link-prev {
    &:focus {
        outline: 0;
    }

    text-decoration: none;
    background-color: transparent;
    border: none;
    background-image: url(../../images/nav-prev.svg);
    width: 10px;
    height: 18px;
    margin-right: 15px;
    display: inline-block;

    &.hidden {
        display: none;
    }
}

.nav-link-next {
    &:focus {
        outline: 0;
    }

    text-decoration: none;
    background-color: transparent;
    border: none;
    background-image: url(../../images/nav-next.svg);
    width: 10px;
    height: 18px;
    margin-left: 15px;
    display: inline-block;

    &.hidden {
        display: none;
    }
}

.finish-good {
    background-image: url(../../images/finish-good.svg);
    width: 44px;
    height: 45px;
    display: inline-block;
}

.finish-bed {
    background-image: url(../../images/finish-bed.svg);
    width: 53px;
    height: 53px;
    display: inline-block;
}

.materials-ic {
    background-image: url(../../images/materials-ic.svg);
    width: 16px;
    height: 22px;
    display: inline-block;
}

.pgf-ic {
    background-image: url(../../images/pgf-ic.svg);
    width: 58px;
    height: 77px;
    display: inline-block;
}

.data-event-ic {
    background-image: url(../../images/data-event.svg);
    width: 15px;
    height: 15px;
    display: inline-block;
}

.time-event-ic {
    background-image: url(../../images/time-event.svg);
    width: 15px;
    height: 15px;
    display: inline-block;

}

.phone-contact-ic {
    background-image: url(../../images/phone-contact-ic.svg);
    width: 21px;
    height: 20px;
    display: inline-block;
}

.mail-contact-ic {
    background-image: url(../../images/mail-contact-ic.svg);
    width: 21px;
    height: 15px;
    display: inline-block;
}

.location-contact-ic {
    background-image: url(../../images/location-contact-ic.svg);
    width: 18px;
    height: 25px;
    display: inline-block;
}

.edit-ic {
    background-image: url(../../images/edit-ic.svg);
    width: 14px;
    height: 14px;
    display: inline-block;
}

.account-filter-ic {
    background-image: url(../../images/account-filter-ic.svg);
    width: 20px;
    height: 20px;
    display: inline-block;
}

.certificates-ic {
    background-image: url(../../images/certificates-ic.svg);
    width: 16px;
    height: 22px;
    display: inline-block;
}

//icon
//btns
a {
    &:hover {
        text-decoration: none;
    }
}

.btn {
    cursor: pointer;
}

.btn-none {
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;

    &:focus {
        outline: none;
    }
}

.btn-red {
    max-width: 250px;
    width: 100%;
    padding: 21.5px 5px;
    text-align: center;
    background-color: $red;
    color: $white;
    display: inline-block;
    border-radius: 5px;
    font-family: $Lato-Semibold;
    transition: 0.3s;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border: none;
    outline: none;

    &:hover {
        background-color: $redHover;
        color: $white;

        &:focus-within {
            background-color: $gray-900;
        }
    }

    &:focus {
        outline: none;
    }
}

.abs-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.link-arrow-red {
    font-family: $Lato-Medium;
    color: $red !important;
    font-size: 1.125rem;
    position: relative;
    padding: 0 35px 0 0;
    display: inline-block;
    line-height: 1.3;
    transition: 0.3s;
    background-color: transparent;
    border: none;
    outline: none;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 12px;
        background-position: center center;
        -webkit-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../../images/red-arrow.svg);
        transition: 0.3s;
    }

    &:hover {
        color: $redHover !important;
        opacity: 1 !important;

        &:after {
            background-image: url(../../images/red-dark-arrow.svg);
        }
    }

    &:focus {
        outline: none;
    }

    &:focus-within {
        color: $gray-900 !important;
        opacity: 1 !important;

        &:after {
            background-image: url(../../images/dark-9-arrow.svg);
        }
    }
}

.btn-red-text {
    color: $red;
    font-family: $Lato-Semibold;
    font-size: 1.25rem;
    line-height: 1.2;
    @media screen and (max-width: 767px) {
        font-size: 1.125rem;
    }
}

.btn-red-dashed {
    color: $red;
    border: none;
    border-bottom: 1px dashed $red;
    padding: 0;
    background-color: transparent;

    &:hover {
        color: $red;
    }
}

.red-arrow-tick {
    padding: 0 25px 0 0;
    border: none;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    color: $red;
    outline: none;

    &:after {
        content: '';
        position: absolute;
        top: 5px;
        right: 0;
        width: 15px;
        height: 8px;
        background-image: url(../../images/red-arrow-tick.svg);
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        background-position: center center;
    }

    &:focus {
        outline: none;
    }
}

.btn-black-dashed {
    display: inline-block;
    color: $gray-900;
    line-height: 1.3;
    border-bottom: 1px dashed $gray-700;
    cursor: pointer;

    &:hover {
        color: $gray-900;
    }
}

.btn-gray-tick {
    display: inline-block;
    position: relative;
    line-height: 1.3;
    color: $gray-900;
    background-color: transparent;
    padding: 0 36px 0 0;
    border: none;
    outline: none;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 5.5px;
        width: 16px;
        height: 9px;
        background-image: url(../../images/select-tick.svg);
        background-position: center center;
        -webkit-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &:focus {
        outline: none;
    }
}

.accordion-btn-certificates {
    position: relative;
    background-color: transparent;
    color: $red;
    line-height: 1.3;
    display: inline-block;
    border: none;
    padding: 0 23px 0 0;
    outline: none;

    &:focus {
        outline: none;
    }

    &:after {
        content: '';
        position: absolute;
        top: 6.5px;
        right: 0;
        width: 13px;
        height: 7px;
        background-position: center center;
        -webkit-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../../images/open-red-tick.svg);
    }

    &.collapsed {
        &:after {
            background-image: url(../../images/close-red-tick.svg);
        }
    }
}

.btn-brd-gray {
    border-radius: 5px;
    border: 1px solid rgba(228, 0, 15, 0.2);
    color: $red;
    padding: 21.5px 5px;
    outline: none;
    max-width: 170px;
    width: 100%;

    &:focus {
        outline: none;
    }

    &:hover {
        color: $red;
    }
}

.btn-gray-dashed {
    color: $gray-600;
    border: none;
    border-bottom: 1px dashed $gray-600;
    padding: 0 0 3px 0;
    background-color: transparent;
    display: inline-block;

    &:hover {
        color: $gray-600;
    }

    &:focus {
        outline: none;
    }
}

//btns

.wrapper {
    width: 920px;
    margin: 200px auto 0;
    overflow: hidden;
}

.box {
    width: 200px;
    margin-left: 80px;
    float: left;

}

//checkbox
.checkbox {
    margin-bottom: 0;
}

.checkbox input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 20px;
}

.checkbox__text {
    position: relative;
    padding: 7px 0 7px 50px;
    cursor: pointer;
}

.checkbox__text:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid $red;
    transition: .3s;
}

.checkbox__text:after {
    content: '';
    position: absolute;
    top: 9.5px;
    left: 6.5px;
    width: 17px;
    height: 11px;
    transition: .3s;
    background-image: url(../../images/red-tick.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0;
}

.checkbox input:checked + .checkbox__text:after {
    opacity: 1;
}

//checkbox
//bg
.bg-gray-4 {
    background: $gray-400;
}

//bg
.mb-10 {
    margin-bottom: 10px;
}

.pt-30 {
    padding-top: 30px;
    @media screen and (max-width: 767px) {
        padding-top: 1rem;
    }
}

.hide {
    display: none;
}