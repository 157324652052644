@font-face {
  font-family: 'Lato-Bold';
  src: url('../../fonts/Lato-Bold.eot');
  src: url('../../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Lato-Bold.svg#Lato-Bold') format('svg'),
  url('../../fonts/Lato-Bold.ttf') format('truetype'),
  url('../../fonts/Lato-Bold.woff') format('woff'),
  url('../../fonts/Lato-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Semibold';
  src: url('../../fonts/Lato-Semibold.eot');
  src: url('../../fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Lato-Semibold.svg#Lato-Semibold') format('svg'),
  url('../../fonts/Lato-Semibold.ttf') format('truetype'),
  url('../../fonts/Lato-Semibold.woff') format('woff'),
  url('../../fonts/Lato-Semibold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-Medium';
  src: url('../../fonts/Lato-Medium.eot');
  src: url('../../fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Lato-Medium.svg#Lato-Medium') format('svg'),
  url('../../fonts/Lato-Medium.ttf') format('truetype'),
  url('../../fonts/Lato-Medium.woff') format('woff'),
  url('../../fonts/Lato-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-Regular';
  src: url('../../fonts/Lato-Regular.eot');
  src: url('../../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Lato-Regular.svg#Lato-Regular') format('svg'),
  url('../../fonts/Lato-Regular.ttf') format('truetype'),
  url('../../fonts/Lato-Regular.woff') format('woff'),
  url('../../fonts/Lato-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


//---- + fonts -------------
$font-family-base: 'Lato-Regular';
$font-size-base: 1rem;
$line-height-base: 1.46;
$body-color: #000;

$Lato-Regular: 'Lato-Regular';
$Lato-Semibold: 'Lato-Semibold';
$Lato-Medium: 'Lato-Medium';
$Lato-Bold: 'Lato-Bold';
$RussoOne-Regular: 'RussoOne-Regular';
$headings-font-weight: normal !default;

$fs-46: 2.875rem;
$fs-36: 2.25rem;
$fs-30: 1.875rem;
$fs-26: 1.625rem;
$fs-22: 1.375rem;
$fs-18: 1.125rem;
$fs-16: 1rem;
$fs-14: 0.875rem;
$fs-12: 0.75rem;
//---- - fonts -------------

//---- + color -------------

$blue: #0060B9 !default;
$yellow: #FFC700 !default;
$black: #000000 !default;
$blue-500: #0E3266 !default;
$blue-dark: #282E41 !default;
$white: #ffffff !default;
$box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !default;



$gray-900: #363636 !default;
$gray-800: #343a40 !default;
$gray-700: #707070 !default;
$gray-650: #828282 !default;
$gray-600: #9D9D9D !default;
$gray-550: #C4C4C4 !default;
$gray-500: #E8E8E8 !default;
$gray-400: #F5F5F5 !default;
$gray-300: #F2F2F2 !default;
$gray-200: #F0F0F0 !default;
$gray-100: #f8f9fa !default;
$topaz: #FFCC81 !default;
$btn-hover: #96694C !default;
$green:   #219653 !default;
$orange:  #F2C94C !default;
$blueHover:    #2A72D5 !default;
$red:          #E4000F !default;
$red-dark:     #E4322B !default;
$redHover:     #BB000C !default;
$cyan:         #CCF3FF !default;
$cyanHover:    #A5E9FF !default;
$secondary: #2C2219;


$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #FFE7E7 !default;
$teal:    #A5E9FF !default;

$border-color:    $gray-500 !default;
//---- - color -------------
$btn-disabled-opacity:        .5 !default;

//---- + container -------------
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1310px
) !default;
//---- - container -------------

//---- + btn -------------
$input-btn-padding-y-lg:      1rem !default;

$primary:       $topaz !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
//---- - btn -------------

//---- + modal -------------
$modal-xl:                          860px !default;
$modal-lg:                          730px !default;
$modal-md:                          690px !default;
$modal-sm:                          530px !default;

$modal-header-padding:              1.25rem !default;
$modal-header-border-color:         $gray-500 !default;

$modal-fade-transform:              translate(-100%, 50%);
$modal-fade-transform:              none;
$modal-show-transform:              translate(0, 50%);
$modal-show-transform:              none;

//---- - modal -------------


//-------
$form-check-input-gutter:               1.875rem !default;


